<template>
  <div>
    <b-row style="margin: 0px; border-bottom: solid 1px black;">
      <b-col cols="12">
        <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
          <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
            <b-row>
              <b-col sm="12" md="3">
                <i class="fa fa-database"></i>
              </b-col>
              <b-col sm="12" md="3" style="padding: 2px;">
                <b-button variant="secondary" style="width: 100%; height: 100%" @click="AddToValList(record_loc[param_data.name.value]['val'], param_data)">{{ $t('wdm16.4194') }}</b-button>
              </b-col>
              <b-col sm="12" md="4" style="padding: 2px;">
                <b-form-select v-model="param_rule_data.op" :disabled="param_rule_data.val_list.length > 0">
                  <template v-for="op in operator">
                    <option v-if="op.param_types.indexOf(param_data.type) !== -1" :value="op.value">
                      {{ $t('wdm16.' + op.label_wdm16_id) }}
                    </option>
                  </template>
                </b-form-select>
              </b-col>
              <b-col sm="12" md="2" style="padding: 2px;">
                <b-dropdown variant="warning" text="" size="md">
                  <template v-if="query_list_type && query_list_type === 'one_query'">
                    <template v-if="polestar">
                      <template v-if="query_list.length === 0">
                        <b-dropdown-item @click="add_new_query('polestar_column')"> {{ $t('wdm16.11230') }} </b-dropdown-item>
                        <b-dropdown-item @click="add_new_query()"> {{ $t('wdm16.11232') }} </b-dropdown-item>
                      </template>
                      <template v-else>
                        <b-dropdown-item @click="add_to_this_query(0, 'polestar_column')">
                          <strong style="color: blue;"> {{ query_list[0].type.label }} => </strong> {{ $t('wdm16.11471') }}
                        </b-dropdown-item>
                        <b-dropdown-item @click="add_to_this_query(0)">
                          <strong style="color: blue;"> {{ query_list[0].type.label }} => </strong> {{ $t('wdm16.11231') }}
                        </b-dropdown-item>
                      </template>
                    </template>
                    <template v-else>
                      <b-dropdown-item v-if="query_list.length === 0" @click="add_new_query()">  {{ $t('wdm16.11232') }}  </b-dropdown-item>
                      <b-dropdown-item v-else @click="add_to_this_query(0, '')">
                        <strong style="color: blue;"> {{ query_list[0].type.label }} => </strong> {{ $t('wdm16.11233') }}
                      </b-dropdown-item>
                    </template>
                  </template>
                  <template v-else>
                    <b-dropdown-item @click="add_new_query()">  {{ $t('wdm16.11232') }}  </b-dropdown-item>
                    <b-dropdown-item v-for="(query, q_ind) in query_list" :key="'query_list_' + q_ind" v-if="query.type.value === data_type" @click="add_to_this_query(q_ind, '')">
                      <strong style="color: blue;"> {{ q_ind + 1 }} ) {{ query.type.label }} => </strong> {{ $t('wdm16.11233') }}
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
              </b-col>
            </b-row>
          </b-card-header>
          <b-row>
            <b-col cols="12">
              <div id="s_operationsScrollbar" style="min-height: 50px; max-height: 150px; overflow-y: scroll; overflow-x: hidden; border-radius: 10px 0 0 10px; padding: 3px">
                <template v-for="(val, val_ind) in param_rule_data.val_list">
                  <b-row class="s_rowNotSelected" @dblclick="param_rule_data.val_list.splice(val_ind, 1); $delete(param_rule_data.labels, val)">
                    <b-col cols="12">
                      <i class="fa fa-caret-right"></i>
                      <span v-if="typeof val === 'object'"> {{ val.label }} </span>
                      <span v-else> {{ val }} </span>
                    </b-col>
                  </b-row>
                </template>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import vSelect from 'vue-select';
import { mapGetters } from 'vuex';
import { RuleEngine } from '@/services/public/functions';

export default {
  name: 'WisdomDataRule',
  props: {
    param_change: {
      type: Object,
      required: true
    },
    param_data: {
      type: Object,
      required: false
    },
    option_loc: {
      type: Array,
      required: false
    },
    record_loc: {
      type: Object,
      required: false
    },
    wdm_name: {
      type: Object,
      required: false
    },
    data_type: {
      type: String,
      required: true
    },
    query_list: {
      type: Array,
      required: false
    },
    query_list_type: {
      type: String,
      required: false
    },
    polestar: {
      type: Boolean,
      required: false
    }
  },
  mounted () {},
  computed: mapGetters({
    lang: 'lang'
  }),
  data () {
    return {
      d_selectedVal: '',
      operator: require('@/options/operator').options,
      param_rule_data: {
        "time_of_value": "any_value",
        "data_type": "old_recorded_data",
        "val_list": [],
        "record_type": "many",
        "significant": "true",
        "requesting_operation": "wisdom_data_save",
        "date": "1",
        "op": "="
      }
    };
  },
  methods: {
    f_paramChangeRule: function () {
      if (this.param_change.rule) {
        this.param_change.rule = 0;
      } else {
        this.param_change.rule = 1;
      }
    },
    AddToValList: function (val, param_data) {
      // console.log(val);
      if (val) {
        if (['select', 'object_list'].indexOf(param_data.type) !== -1) {
          let det = false;
          for (let i in this.param_rule_data.val_list) {
            if (this.param_rule_data.val_list[i].value === val.value) {
              det = true;
              alert('bu değer zaten eklenmiştir.');
              return;
            }
          }
          if (!det) { this.param_rule_data.val_list.push(val); }
        } else { // integer, float
          this.param_rule_data.val_list = [];
          this.param_rule_data.val_list.push(val);
        }
      }
      this.$forceUpdate();
    },
    add_new_query: function (type = '') {
      let rule_data = JSON.parse(JSON.stringify(this.param_rule_data));
      let query = {};
      query = {
        'fields': [],
        'significant': 'true',
        'date': rule_data.date,
        'type': { 'label': this.wdm_name.label, 'value': this.data_type },
        'data_type': rule_data.data_type,
        'time_of_value': rule_data.time_of_value,
        'compile_str_array': [],
        'compile_str': ''
      };
      let field_data_list = this.field_data_create(type);
      for (let f in field_data_list) {
        query.fields.push(field_data_list[f]);
        this.compile_str_func(query, 'new');
      }
      // console.log(query);
      this.query_list.push(query);
      // this.field_loc_func();
      this.f_paramChangeRule();
    },
    add_to_this_query: function (query_ind, type = '') {
      let field_data_list = this.field_data_create(type);
      for (let f in field_data_list) {
        let det = false;
        for (let i in this.query_list[query_ind].fields) {
          if (JSON.stringify(field_data_list[f]) === JSON.stringify(this.query_list[query_ind].fields[i])) {
            this.query_list[query_ind].fields[i].values = JSON.parse(JSON.stringify(field_data_list[f].values));
            det = true;
            break;
          }
        }
        if (!det) {
          this.query_list[query_ind].fields.push(field_data_list[f]);
          this.compile_str_func(this.query_list[query_ind], 'field');
        }
      }
      // this.field_loc_func();
      this.f_paramChangeRule();
    },
    compile_str_func: function (query, type) {
      if (type === 'new') {
        query.compile_str_array = ['(', 0, ')'];
      } else if (type === 'field') {
        let last_field_index = query.fields.length - 1;
        query.compile_str_array.splice(query.compile_str_array.length - 1, 1);
        query.compile_str_array.push('and');
        query.compile_str_array.push(last_field_index);
        query.compile_str_array.push(')');
      }
    },
    field_data_create: function (type = '') {
      let rule_data = JSON.parse(JSON.stringify(this.param_rule_data));
      let option_loc_data = JSON.parse(JSON.stringify(this.option_loc));
      let value_type = this.param_data.type;
      let field_data_list = [];
      // backendde sorgularken tipinin object_list_options olmasına ve level değerine bakıyoruz.
      if (this.param_data.type === 'object_list') {
        // her bir value için yani her bir object list options elemanı için yeni bir field oluşturuluyor
        for (let i in rule_data.val_list) {
          let object_list_options_param_location = {
            'label': this.param_data.object_param.label,
            'value': this.param_data.object_param.value,
            'type': 'object_list_options'
          };
          option_loc_data = JSON.parse(JSON.stringify(this.option_loc));
          option_loc_data[option_loc_data.length - 1] = object_list_options_param_location;
          let detail_param_location = {
            'label': rule_data.val_list[i].label,
            'value': rule_data.val_list[i].value,
            'type': 'detail'
          };
          option_loc_data.push(detail_param_location);
          value_type = 'object_list_options';
          let val_list = [];
          let values = [];
          // val_list.push(rule_data.val_list[i]);
          // values.push(rule_data.val_list[i].value);
          let field_data = {
            'value_type': value_type,
            'significant': rule_data.significant,
            'using_data_if_not_reaching_record_data': 'true',
            'val_list': val_list,
            'values': values,
            'operation': rule_data.op,
            'loc': option_loc_data
          };
          if (option_loc_data[0].value === 'anatomy') {
            field_data.anatomy_use = 'any';
          }
          if (type && type === 'polestar_column') {
            field_data.is_column = 1;
          }
          field_data_list.push(field_data);
        }
      } else {
        let values = [];
        if (['select'].indexOf(this.param_data.type) !== -1) {
          for (let i in rule_data.val_list) {
            values.push(rule_data.val_list[i].value);
          }
        } else if (['integer', 'float'].indexOf(this.param_data.type) !== -1) {
          values = rule_data.val_list;
        }
        let field_data = {
          'value_type': value_type,
          'significant': rule_data.significant,
          'using_data_if_not_reaching_record_data': 'true',
          'val_list': rule_data.val_list,
          'values': values,
          'operation': rule_data.op,
          'loc': option_loc_data
        };
        if (option_loc_data[0].value === 'anatomy') {
          field_data.anatomy_use = 'any';
        }
        if (type && type === 'polestar_column') {
          field_data.is_column = 1;
        }
        field_data_list.push(field_data);
      }
      return field_data_list;
    },
    field_loc_func: function () {
      RuleEngine.field_tree_calculate(this.query_list);
      this.$forceUpdate();
    }
  },
  watch: {},
  components: {}
};

</script>

<style>
.info-wisdom {
  color: red;
  background-color: #abf1d1;
  border-radius: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

</style>

