<!-- !!!!!!!!!!!!! RİGHT BETWEEN DİV TAGS TO TEST YOUR RENDERED TEMPLATE THAT YOU LOGGED TO CONSOLE. -->
<!-- 
<template>
  <div></div>
</template>
 -->

<script>
import { ClsWdm, ClsWdmrForever } from '@/services/public/wdm';
import { ClsUserSettings } from '@/services/public/user';
import Vue from 'vue';
import {
  default as WisdomParam
} from "./WisdomParam";
import {
  default as WdmrSchema
} from "./WdmrSchema";
import {
  default as WdmrOldData
} from "./WdmrOldData";
import vSelect from 'vue-select';
import Datepicker from 'wisdom-vue-datepicker';
import { mapGetters } from 'vuex';
import moment from 'moment';
import {
  default as Anatomy
} from './Anatomy';
import OptionService from '@/services/option';

var templateRenderFns = [];
export default {
  name: 'Wdmr',
  render (h) {
    return h('div', [
      (this.template ? this.template() : '')
    ]);
  },
  staticRenderFns: templateRenderFns,
  components: {
    vSelect,
    Datepicker,
    Anatomy,
    WisdomParam,
    WdmrOldData,
    WdmrSchema
  },
  props: {
    p_wdmrSettings: {
      type: Object,
      required: false
    },
    wdm_version_wisdomera: {
      type: Object,
      required: false
    },
    data: {
      type: Object,
      required: true
    },
    option_data: {
      type: Object,
      required: true
    },
    data_type: {
      type: String,
      required: true
    },
    rule_engine: {
      type: Boolean,
      required: false,
      default: false
    },
    d_showWdmrOldDataRegion: {
      type: Boolean,
      required: false,
      default: false
    },
    query_list: {
      type: Array,
      required: false,
      default: []
    },
    query_list_type: {
      type: String,
      required: false
    },
    parameter_list: {
      type: Array,
      required: false
    },
    user_favorite_parameter_list: {
      type: Array,
      required: false
    },
    dont_show_caption_index_data: {
      type: Array,
      required: false
    },
    stage_data: {
      type: Object,
      required: false
    },
    use_parameter_list: {
      type: Boolean,
      required: false
    },
    polestar: {
      type: Boolean,
      required: false
    },
    external_patient_data: {
      type: Object,
      required: false
    },
    test_mode: {
      type: Boolean,
      required: false,
      default: false
    },
    column_list: {
      type: Array,
      required: false,
      default: () => []
    },
    param_change: {
      type: Object,
      required: true
    },
    p_schemaEdit: {
      type: Object,
      required: false,
      default: () => {}
    },
    mother_children: {
      type: Object,
      required: true,
      default: () => {}
    },
    d_showModal: {
      type: Object,
      required: true,
      default: () => {}
    },
    d_patientSearchModalData: {
      type: Object,
      required: true,
      default: () => {}
    },
    p_aiRobotMatchParameterResult: {
      type: Object,
      required: false
    },
    p_selectedAnatomySchemaList: {
      type: Array,
      required: false
    },
    p_multiWdmrIndex: {
      type: Number,
      required: false
    }
  },
  computed: mapGetters({
    lang: 'lang',
    help: 'help',
    patient_tmp: "patient_selected"
  }),
  mounted () {
    if (!this.rule_engine) {
      this.data.patient_id = this.patient.id;
      if (this.data.general && !this.data.general.date) {
        let today = new Date();
        this.data.general.date = moment(today).format('YYYY-MM-DD');
      }
    }
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.external_patient_data) {
      this.patient = JSON.parse(JSON.stringify(this.external_patient_data))
    } else {
      this.patient = JSON.parse(JSON.stringify(this.patient_tmp))
    }
    if (!this.option_data[this.data_type].schema || this.option_data[this.data_type].schema !== 'yes' || this.p_schemaEdit || this.rule_engine || this.polestar) {
      this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
    }
    let bool = true;
    if (['laboratory', 'wisdom_data_model_3'].indexOf(this.data_type) !== -1) {
      bool = false;
    }
    for (let i in this.option_data[this.data_type].param_group.param_order) {
      this.d_groupShowList[this.option_data[this.data_type].param_group.param_order[i]] = { 'show': this.p_wdmrSettings.group.show, 'show_timer': false };
    }
    this.render_template();
  },
  data () {
    return {
      d_lastKey: '',
      d_lastOptionLoc: '',
      d_lastSecondOptionLoc: '',
      d_clickEvent: {
        'x': 0,
        'y': 0
      },
      d_wisdomParamData: {
        'anatomy': '',
        'data': '',
        'param': '',
        'show': false,
        'option_loc': '',
        'option_param_mother': ''
      },
      d_showAnatomyComponent: false,
      d_labelValueDocument: '',
      d_groupShowList: {},
      d_showAnatomySchemaRegion: false,
      template: null,
      selected_schema_data: {},
      schema_scope: 'public',
      schema_caption: '',
      selected_schema: '',
      schema_list: [],
      diagnosis_options: [],
      is_all_drugs: false,
      show_staging_group_selectable: true,
      stage_automatic: true,
      subbb: [],
      subbb_ind: 0,
      selected_anatomy_region: {},
      detail_pr: [],
      list_pr: [],
      detail_id: '',
      list_id: '',
      d_levelCount: 3, // this is defined as default value, to be in safe.
      d_levelType: '',
      selected_anatomy_parent: {},
      user: {},
      patient: {},
      option_location: [],
      d_icons: {
        '2956643': require('../../icon/2956643.png'),
        'W3D_humanbody': require('../../icon/W3D_humanbody.png'),
        '1319596': require('../../icon/1319596.png')
      }
    };
  },
  methods: {
    render_template: function () {
      this.subbb_ind = 0; // When we call this function again, this.d_level must be 0.
      let max_deeper_value = ClsWdm.maxDeeperLevel(this.option_data[this.data_type]);
      if (max_deeper_value) {
        this.d_levelCount = max_deeper_value;
      }
      // console.log(this.d_levelCount);
      let html = this.build_form();
      // console.log(html);
      let compiled_template = Vue.compile(html);
      this.template = compiled_template.render;
      templateRenderFns.length = 0;
      for (var i in compiled_template.staticRenderFns) {
        templateRenderFns.push(compiled_template.staticRenderFns[i]);
      }
    },
    build_form: function () {
      // console.log('build_form');
      let html = '';
      html += '<wdmr-old-data v-if="d_showWdmrOldDataRegion && !rule_engine && !polestar" :data_type="data_type" :option_data="option_data" :data="data" :patient_id="patient.id" :mother_children="mother_children"></wdmr-old-data>';
      html += '<wdmr-schema v-if="p_wdmrSettings.wdmr_schema.wdm.show && option_data[data_type].schema === \'yes\' && !p_schemaEdit && !rule_engine && !polestar" :p_schemaType="\'wdm\'" :data_type="data_type" :option_data="option_data" :data="data" :mother_children="mother_children" :p_wdmrSettings="p_wdmrSettings"></wdmr-schema>';
      html += '<b-row style="margin: 0px;">';
      html += '<template v-for="(group, group_ind) in option_data[data_type].param_group.param_order" v-if="f_favoriteShowControl(group)">';
      // NOT ANATOMY START
      // <<<
      html += '<b-col :cols="12 / p_wdmrSettings.column_settings.cols" style="padding: 3px;">';
      html += '<template v-if="group !== \'anatomy\' && mother_children.d_showNonAnatomyData">';
      html += '<b-card class="mb-1" style="border: none;" no-body header-tag="header" footer-tag="footer" :key="\'param_order-not_anatomy-b_card-\' + group_ind">';
      html += '<template v-if="p_wdmrSettings.group.mode === 1">';
      html += '</template>';
      html += '<template v-else-if="p_wdmrSettings.group.mode === 2">';
      html += '<span style="background-color: #c8ced3; border-radius: 5px; padding: 3px;"><strong>{{ option_data[data_type].param_group.parameters[group].label }}</strong></span>';
      html += '</template>';
      html += '<template v-else-if="p_wdmrSettings.group.mode === 3">';
      html += '<b-card-header :header-bg-variant="d_groupShowList[group].show ? \'secondary\' : \'primary\'" class="p-1">';
      html += '<b-row style="cursor: pointer;">';
      html += '<b-col sm="12" lg="10" cols="10" @click="f_showGroup(group)">';
      html += '<strong>{{ option_data[data_type].param_group.parameters[group].label }}</strong>';
      html += '<small> ( {{ option_data[data_type][group].param_order.length }} ) </small>'
      html += '<span style="color: black; float: right;" v-if="d_groupShowList[group].show_timer && !d_groupShowList[group].show">{{ $t(\'wdm16.13176\') }}</span>';
      html += '</b-col>';
      html += '<b-col sm="12" lg="2" style="text-align: right;">';
      /*
        html += '<b-button size="md" variant="warning" style="margin-right: 5px; padding: 0px;" @click="' + this.f_parameterString() + '.view_mode && ' + this.f_parameterString() + '.view_mode === \'column_mode\' ? $delete(' + this.f_parameterString() + ', \'view_mode\') : ' + this.f_parameterString() + '.view_mode = \'column_mode\'; $forceUpdate(); param_change_func();">';
        html += '<template v-if="' + this.f_parameterString() + '.view_mode && ' + this.f_parameterString() + '.view_mode === \'column_mode\'">';
        html += '<img :src="d_icons[\'2956643\']" title="Kolon Modu" style="width: 2.2em;" />';
        html += '</template>';
        html += '<template v-else>';
        html += '<img :src="d_icons[\'1319596\']" title="Normal Mod" style="width: 2.2em;" />';
        html += '</template>';
        html += '</b-button>';
      */
      html += '</b-col>';
      html += '</b-row>';
      html += '</b-card-header>';
      html += '</template>';
      html += '<div v-if="d_groupShowList[group].show" :id="\'group_\' + group">';
      // html += '{{ this.document.getElementById("group_3000") }}';
      this.is_anatomy = false;
      html += this.main_param_func();
      html += '</div>';
      html += '</b-card>';
      html += '</template>';
      // >>>
      // *************
      // ANATOMYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYY
      // ANATOMY START
      // <<<
      html += '<template v-if="group === \'anatomy\'">';
      html += '<b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit(\'help_no\', \'152\')">?</b-badge>';
      html += this.f_createAnatomyComponent();
      // ANATOMY SCHEMA START
      // <<<
      html += '<wdmr-schema v-if="p_wdmrSettings.wdmr_schema.wdm_anatomy.show && !p_schemaEdit && !rule_engine && !polestar" :p_schemaType="\'wdm_anatomy\'" :data_type="data_type" :option_data="option_data" :data="data" :mother_children="mother_children" :p_wdmrSettings="p_wdmrSettings" :p_selectedAnatomySchemaList="p_selectedAnatomySchemaList"></wdmr-schema>';
      // >>>
      // ANATOMY SCHEMA END
      // ************************************
      // ANATOMY KEYS LOOP START
      // <<<
      html += '<b-row style="margin: 0px;">';
      html += '<template v-if="mother_children.d_showAnatomyData && mother_children.d_showNonAnatomyData" v-for="(ana, ana_ind) in data[\'data\'].anatomy">';
      // html += '<b-card :key="\'anatomy_signs_\' + ana_ind" no-body header-tag="header" footer-tag="footer" style="margin-bottom: 3px;">';
      // anatomy card starts
      html += '<b-col sm="12" :lg="12 / p_wdmrSettings.anatomy_group.cols" style="padding: 3px;">';
      html += '<b-card no-body header-tag="header" footer-tag="footer" style="margin-bottom: 3px;">';
      html += '<b-card-header header-bg-variant="secondary" class="p-1">';
      html += '<b-row>';
      html += '<b-col sm="12" md="9">';
      html += '<img :src="d_icons[\'W3D_humanbody\']" style="width: 1.3em;" />';
      html += '<strong style="color: #c57c26;">{{ ana.name.label.toLocaleUpperCase(\'TR\') }}</strong>';
      html += '<b-dropdown left variant="secondary" size="sm" style="width: 50px;">';
      html += '<b-dropdown-item @click="$delete(data[\'data\'].anatomy, ana_ind); $forceUpdate();">'
      html += '<i class="icon-trash"></i> sil';
      html += '</b-dropdown-item>';
      html += '<template v-for="(copy_ana, copy_ana_ind) in data[\'data\'].anatomy">';
      html += '<b-dropdown-item v-if="copy_ana_ind !== ana_ind" @click="copy_anatomy_data_to_this_anatomy(copy_ana_ind, ana_ind)" :key="\'anatomy-dropdown-\' + copy_ana_ind">';
      html += '<i class="fa fa-copy"></i> {{ copy_ana.name.label }} {{ $t(\'wdm16.13178\') }}';
      html += '</b-dropdown-item>';
      html += '</template>';
      html += '</b-dropdown>';
      html += '</b-col>';
      html += '<b-col sm="12" md="3" style="cursor: pointer;" v-b-toggle="\'anatomy-region-\' + ana_ind">';
      html += '</b-col>';
      html += '</b-row>';
      html += '</b-card-header>';
      html += '<b-collapse v-if="p_wdmrSettings.group.show" :id="\'anatomy-region-\' + ana_ind" visible>';
      this.is_anatomy = true;
      this.subbb_ind = 0;
      html += this.main_param_func();
      html += '</b-collapse>';
      html += '</b-card>';
      // anatomy card ends
      html += '</b-col>';
      html += '</template>';
      html += '</b-row>';
      // anatomy for loop template ends
      html += '</template>';
      html += '</b-col>';
      // belowe template is group loop's end
      html += '</template>';
      html += '</b-row>';
      html += this.f_createWisdomParamPopup();
      // >>>
      // ANATOMY END
      // html += '</template>';
      return '<div :id="p_multiWdmrIndex === undefined ? \'wdmr_location\' : p_multiWdmrIndex.toString() + \'_wdmr_location\'" @click="f_leftClickWdmr" tabindex="0" @keydown="f_WdmrKeyDown" @keyup="f_WdmrKeyUp">' + html + '</div>';
    },
    main_param_func: function () {
      let html = '';
      this.subbb_ind += 1;
      // we have to take record data because before this main_param_function, we change the d_levelTYpe, so record_data_location is being wrong.
      let record_data_location = this.f_recordData();
      let param_record_data = record_data_location + '[pr' + this.subbb_ind.toString() + ']';
      // console.log('this.subbb_ind: ', this.subbb_ind);
      // console.log('param_record_data ', param_record_data);
      // console.log('this.d_levelType ', this.d_levelType);
      this.f_calculateParamLocation_detailed();
      // console.log('this.option_location: ', JSON.stringify(this.option_location));
      // html += '<b-row v-if="' + this.f_parameterMotherString() + '.view_mode && ' + this.f_parameterMotherString() + '.view_mode === \'column_mode\'">';
      html += '<template v-for="(pr' + this.subbb_ind + ', pr' + this.subbb_ind + '_ind) in ' + this.f_paramorderString() + '">';
      if (this.d_levelType === 'detail') {
        html += '<template v-if="calculate_if(' + record_data_location + ', pr' + this.subbb_ind.toString() + ', ' + this.f_parameterString() + ', ' + this.f_parameterMotherString() + ', k' + (this.subbb_ind - 1).toString() + ', ' + this.f_anatomyRegion() + ', group, [' + this.option_location.join(', ') + '])">';
      } else {
        html += '<template v-if="calculate_if(' + record_data_location + ', pr' + this.subbb_ind.toString() + ', ' + this.f_parameterString() + ', ' + this.f_parameterMotherString() + ', \'\', ' + this.f_anatomyRegion() + ', group, [' + this.option_location.join(', ') + '])">';
      }
      // *******************************
      // WISDOM-PARAM START
      // <<<
      let wisdom_param_html = this.f_createWisdomParam(record_data_location);
      html += this.f_wisdomParamMode_1(wisdom_param_html);
      html += this.f_wisdomParamMode_2(param_record_data, record_data_location);
      html += this.f_wisdomParamMode_3(param_record_data, record_data_location);
      // >>>
      // WISDOM-PARAM END
      // *******************************
      html += this.f_createObjectListOptions(param_record_data);
      html += this.f_createListParameter(param_record_data);
      html += '</template>';
      html += '</template>';
      return html;
    },
    f_createAnatomyButtons: function () {
      let html = '';
      html += '<b-button variant="success" title="Anatomik bölge ekle" style="margin-right: 5px;" v-if="selected_anatomy_region" @click="add_anatomy()">';
      html += '<i class="icon-plus"></i>';
      html += '</b-button>';
      html += '<b-button variant="danger" :title="$t(\'wdm16.13179\')" style="margin-right: 5px;" @click="f_cleanAnatomyData()">';
      html += '<i class="fa fa-trash"></i>';
      html += '</b-button>';
      /*
        html += '<b-button size="md" variant="warning" style="margin-right: 5px; padding: 0px;" @click="option_data[data_type][\'anatomy\'].view_mode && option_data[data_type][\'anatomy\'].view_mode === \'column_mode\' ? $delete(option_data[data_type][\'anatomy\'], \'view_mode\') : option_data[data_type][\'anatomy\'].view_mode = \'column_mode\'; $forceUpdate(); param_change_func();">';
        html += '<template v-if="option_data[data_type][\'anatomy\'].view_mode && option_data[data_type][\'anatomy\'].view_mode === \'column_mode\'">';
        html += '<img :src="d_icons[\'2956643\']" title="Kolon Modu" style="width: 2.2em;" />';
        html += '</template>';
        html += '<template v-else>';
        html += '<img :src="d_icons[\'1319596\']" title="Normal Mod" style="width: 2.2em;" />';
        html += '</template>';
        html += '</b-button>';
      */
      return html;
    },
    f_createAnatomyComponent: function () {
      let html = '';
      html += '<template v-if="p_wdmrSettings && p_wdmrSettings.anatomy_component.mode === 1">';
      // HERE WE CONTROL PAGE DIVIDE. BECAUSE WHEN PAGE DIVIDED WE DONT HAVE LOCATION TO PUT BUTTONS
      html += '<template v-if="p_wdmrSettings.page_divide > 1">';
      html += '<b-row key="anatomy" style="margin: 0px;">';
      html += '<b-col sm="12" md="12" style="padding-right: 3px;">';
      html += '<anatomy :external_patient_data="external_patient_data" :param_change="param_change" :selected_anatomy_region="selected_anatomy_region" :selected_anatomy_parent="selected_anatomy_parent" :key="\'param_order-anatomy-widget-\' + group_ind" :patient_follow="patient.selected ? true : false"></anatomy>';
      html += '</b-col>';
      html += '</b-row>';
      html += '<b-row>';
      html += '<b-col sm="12" md="12" style="padding-right: 3px; text-align: right;">';
      html += this.f_createAnatomyButtons();
      html += '</b-col>';
      html += '</b-row>';
      html += '</template>';
      // WE HAVE ONLY ONE COLUMN, THEN WE CAN USE 9 - 3 B-COLS OF B-ROW
      html += '<template v-else>';
      html += '<b-row key="anatomy" style="margin: 0px;">';
      html += '<b-col sm="12" md="9" style="padding-right: 3px;">';
      html += '<anatomy :external_patient_data="external_patient_data" :param_change="param_change" :selected_anatomy_region="selected_anatomy_region" :selected_anatomy_parent="selected_anatomy_parent" :key="\'param_order-anatomy-widget-\' + group_ind" :patient_follow="patient.selected ? true : false"></anatomy>';
      html += '</b-col>';
      html += '<b-col sm="12" md="3" style="text-align: right;">';
      html += this.f_createAnatomyButtons();
      html += '</b-col>';
      html += '</b-row>';
      html += '</template>';
      html += '</template>';
      html += '<template v-else-if="p_wdmrSettings && p_wdmrSettings.anatomy_component.mode === 2">';
      html += '<b-row style="margin: 0px;">';
      html += '<b-col sm="12" md="12">';
      html += '<b-button size="md" style="margin-right: 5px; padding: 0px;" variant="secondary" @click="d_showAnatomyComponent = true" :title="$t(\'wdm16.11278\')">';
      html += '<img :src="d_icons[\'W3D_humanbody\']" style="width: 2.5em;" />';
      html += '</b-button>';
      html += '</b-col>';
      html += '</b-row>';
      html += '<b-modal v-if="d_showAnatomyComponent" v-model="d_showAnatomyComponent" hide-footer :title="$t(\'wdm16.11278\')" centered header-bg-variant="info" header-text-variant="dark">';
      html += '<p class="my-4" style="word-wrap: break-word; min-height: 300px;">';
      html += '<anatomy :external_patient_data="external_patient_data" :param_change="param_change" :selected_anatomy_region="selected_anatomy_region" :selected_anatomy_parent="selected_anatomy_parent" :key="\'param_order-anatomy-widget-\' + group_ind" :patient_follow="patient.selected ? true : false"></anatomy>';
      html += this.f_createAnatomyButtons();
      html += '</p>';
      html += '</b-modal>';
      html += '</template>';
      return html;
    },
    f_wisdomParamMode_1: function (wisdom_param_html) {
      let html = '';
      html += '<template v-if="p_wdmrSettings.wisdom_param.mode === 1">';
      html += wisdom_param_html;
      html += '</template>';
      return html;
    },
    f_wisdomParamMode_2: function (param_record_data, record_data_location) {
      let id_str_not_filled = 'p_multiWdmrIndex === undefined ? \'not_filled_label_value_id_\' + f_getIndexList([' + this.option_location.join(', ') + ']) : p_multiWdmrIndex.toString() + \'_not_filled_label_value_id_\' + f_getIndexList([' + this.option_location.join(', ') + '])';
      let id_str_value_label = 'p_multiWdmrIndex === undefined ? \'value_label_value_id_\' + f_getIndexList([' + this.option_location.join(', ') + ']) : p_multiWdmrIndex.toString() + \'_value_label_value_id_\' + f_getIndexList([' + this.option_location.join(', ') + '])';
      let wisdom_param_val_not_filled = '<div :id="' + id_str_not_filled + '" style="border: dotted 1px rgba(158, 193, 179, 0.7); min-width: 100px; min-height: 15px; border-radius: 5px; padding: 3px;">.........</div>';
      let html = '';
      let show_wisdom_param_modal_click = 'f_showWisdomParamPopup([' + this.option_location.join(', ') + '], ' + record_data_location + ', pr' + this.subbb_ind.toString() + ', ' + this.f_wisdomParamAnatomy() + ')';
      html += '<template v-else-if="p_wdmrSettings.wisdom_param.mode === 2">';
      html += ' <b-row :style="f_getLabelValueStyle2([' + this.option_location.join(', ') + '])" @click="' + show_wisdom_param_modal_click + '">';
      html += '   <b-col sm="12" lg="12">';
      html += '     <template v-if="[\'text\', \'textarea\', \'integer\', \'float\', \'datetime\', \'date\'].indexOf(' + this.f_parameterString() + '.type) !== -1">';
      html += '       <template v-if="' + param_record_data + ' && ' + param_record_data + '.val">';
      html += this.f_calculateCaptionHtml(true);
      html += '         <div :id="' + id_str_value_label + '" :style="f_getValueStyle()">';
      html += '           {{' + param_record_data + '.val }} {{ p_wdmrSettings.wisdom_param.user_style ? p_wdmrSettings.wisdom_param.style.value.after : option_data[data_type].style ? option_data[data_type].style.value.after : \'\' }} ';
      html += '         </div>';
      html += '       </template>';
      html += '       <template v-else>';
      html += this.f_calculateCaptionHtml(false);
      html += wisdom_param_val_not_filled;
      html += '       </template>';
      html += '     </template>';
      // select radiogroup
      html += '     <template v-else-if="[\'radiogroup\', \'select\'].indexOf(' + this.f_parameterString() + '.type) !== -1">';
      html += '       <template v-if="' + param_record_data + ' && ' + param_record_data + '.val && ' + param_record_data + '.val.label">';
      html += this.f_calculateCaptionHtml(true);
      html += '         <div :id="' + id_str_value_label + '" :style="f_getValueStyle()">';
      html += '           {{' + param_record_data + '.val.label }} {{ p_wdmrSettings.wisdom_param.user_style ? p_wdmrSettings.wisdom_param.style.value.after : option_data[data_type].style ? option_data[data_type].style.value.after : \'\' }} ';
      html += '         </div>';
      html += '       </template>';
      html += '       <template v-else>';
      html += this.f_calculateCaptionHtml(false);
      html += wisdom_param_val_not_filled;
      html += '       </template>';
      html += '     </template>';
      // checkbox. val includes list of value label objects [{'label': '', 'value': ''}]
      html += '     <template v-else-if="[\'checkbox\'].indexOf(' + this.f_parameterString() + '.type) !== -1">';
      html += '       <template v-if="' + param_record_data + ' && ' + param_record_data + '.val && ' + param_record_data + '.val.length > 0">';
      html += this.f_calculateCaptionHtml(true);
      html += '         <div :id="' + id_str_value_label + '" :style="f_getValueStyle()">';
      html += '           {{ f_getArrayLabelList(' + param_record_data + '.val) }} {{ p_wdmrSettings.wisdom_param.user_style ? p_wdmrSettings.wisdom_param.style.value.after : option_data[data_type].style ? option_data[data_type].style.value.after : \'\' }} ';
      html += '         </div>';
      html += '       </template>';
      html += '       <template v-else>';
      html += this.f_calculateCaptionHtml(false);
      html += wisdom_param_val_not_filled;
      html += '       </template>';
      html += '     </template>';
      html += '     <template v-else>';
      html += this.f_calculateCaptionHtml(false);
      html += '     </template>';
      //
      html += '   </b-col>';
      html += ' </b-row>';
      // html += '<div :id="\'popup_label_value_id_\' + f_getIndexList([' + this.option_location.join(', ') + '])" style="display: none; border: solid 1px black;">';
      /*
      html += '<b-row>';
      html += '<b-col cols="12">';
      html += '<b-button class="pull-right" variant="danger" @click="f_leftClickWdmr">-</b-button>';
      html += '</b-col>';
      html += '</b-row>';
      */
      // html += wisdom_param_html;
      // html += '</div>';
      html += '</template>';
      return html;
    },
    f_getLabelValueStyle2: function (option_loc) {
      let style = 'border: 1px solid rgba(158, 193, 179, 0.3); margin: 0px;';
      if (this.d_lastOptionLoc && JSON.stringify(this.d_lastOptionLoc) === JSON.stringify(option_loc)) {
        style += 'border-bottom: solid 4px green;';
      }
      return style;
    },
    f_getLabelValueStyle3: function (option_loc) {
      let style = 'margin: 5px; display: inline-block;';
      if (this.d_lastOptionLoc && JSON.stringify(this.d_lastOptionLoc) === JSON.stringify(option_loc)) {
        style += 'border-bottom: solid 4px green;';
      }
      return style;
    },
    f_getValueStyle: function () {
      let style = '';
      // if (!this.option_data[this.data_type].style) {
      if (this.p_wdmrSettings.wisdom_param.user_style) {
        style += 'font-size: ' + this.p_wdmrSettings.wisdom_param.style.value.fontSize + 'px;';
        style += 'border-radius: ' + this.p_wdmrSettings.wisdom_param.style.value.borderRadius + 'px;';
        style += 'color: ' + this.p_wdmrSettings.wisdom_param.style.value.color + ';';
        style += 'background-color: ' + this.p_wdmrSettings.wisdom_param.style.value.backgroundColor + ';';
        style += 'border: ' + this.p_wdmrSettings.wisdom_param.style.value.border.type + ' ' + this.p_wdmrSettings.wisdom_param.style.value.border.size + 'px ' + this.p_wdmrSettings.wisdom_param.style.value.border.color + ';';
      } else {
        style += 'font-size: ' + this.option_data[this.data_type].style.value.fontSize + 'px;';
        style += 'border-radius: ' + this.option_data[this.data_type].style.value.borderRadius + 'px;';
        style += 'color: ' + this.option_data[this.data_type].style.value.color + ';';
        style += 'background-color: ' + this.option_data[this.data_type].style.value.backgroundColor + ';';
        style += 'border: ' + this.option_data[this.data_type].style.value.border.type + ' ' + this.option_data[this.data_type].style.value.border.size + 'px ' + this.option_data[this.data_type].style.value.border.color + ';';
      }
      return style;
    },
    f_getCaptionStyle: function () {
      let style = '';
      // if (!this.option_data[this.data_type].style) {
      if (this.p_wdmrSettings.wisdom_param.user_style) {
        style += 'font-size: ' + this.p_wdmrSettings.wisdom_param.style.caption.fontSize + 'px;';
        style += 'border-radius: ' + this.p_wdmrSettings.wisdom_param.style.caption.borderRadius + 'px;';
        style += 'color: ' + this.p_wdmrSettings.wisdom_param.style.caption.color + ';';
        style += 'background-color: ' + this.p_wdmrSettings.wisdom_param.style.caption.backgroundColor + ';';
        style += 'border: ' + this.p_wdmrSettings.wisdom_param.style.caption.border.type + ' ' + this.p_wdmrSettings.wisdom_param.style.caption.border.size + 'px ' + this.p_wdmrSettings.wisdom_param.style.caption.border.color + ';';
      } else {
        if (this.option_data[this.data_type].style) {
          style += 'font-size: ' + this.option_data[this.data_type].style.caption.fontSize + 'px;';
          style += 'border-radius: ' + this.option_data[this.data_type].style.caption.borderRadius + 'px;';
          style += 'color: ' + this.option_data[this.data_type].style.caption.color + ';';
          style += 'background-color: ' + this.option_data[this.data_type].style.caption.backgroundColor + ';';
          style += 'border: ' + this.option_data[this.data_type].style.caption.border.type + ' ' + this.option_data[this.data_type].style.caption.border.size + 'px ' + this.option_data[this.data_type].style.caption.border.color + ';';
        }
      }
      return style;
    },
    f_wisdomParamMode_3: function (param_record_data, record_data_location) {
      let id_str_not_filled = 'p_multiWdmrIndex === undefined ? \'not_filled_label_value_id_\' + f_getIndexList([' + this.option_location.join(', ') + ']) : p_multiWdmrIndex.toString() + \'_not_filled_label_value_id_\' + f_getIndexList([' + this.option_location.join(', ') + '])';
      let id_str_value_label = 'p_multiWdmrIndex === undefined ? \'value_label_value_id_\' + f_getIndexList([' + this.option_location.join(', ') + ']) : p_multiWdmrIndex.toString() + \'_value_label_value_id_\' + f_getIndexList([' + this.option_location.join(', ') + '])';
      let wisdom_param_val_not_filled_span = '<span :id="' + id_str_not_filled + '" style="border: dotted 1px rgba(158, 193, 179, 0.7); min-width: 100px; min-height: 15px; border-radius: 5px; padding: 3px;">.........</span>';
      let html = '';
      // param mode 33333333333
      let show_wisdom_param_modal_click = 'f_showWisdomParamPopup([' + this.option_location.join(', ') + '], ' + record_data_location + ', pr' + this.subbb_ind.toString() + ', ' + this.f_wisdomParamAnatomy() + ')';
      // console.log('show_wisdom_param_modal_click ', show_wisdom_param_modal_click);
      html += '<template v-else-if="p_wdmrSettings.wisdom_param.mode === 3">';
      html += ' <div @click="' + show_wisdom_param_modal_click + '" :style="f_getLabelValueStyle3([' + this.option_location.join(', ') + '])">';
      html += '   <template v-if="[\'text\', \'textarea\', \'integer\', \'float\', \'datetime\', \'date\'].indexOf(' + this.f_parameterString() + '.type) !== -1">';
      html += '     <template v-if="' + param_record_data + ' && ' + param_record_data + '.val">';
      html += this.f_calculateCaptionHtml(true);
      html += '       <span :id="' + id_str_value_label + '" :style="f_getValueStyle()">';
      html += '         {{' + param_record_data + '.val }} {{ p_wdmrSettings.wisdom_param.user_style ? p_wdmrSettings.wisdom_param.style.value.after : option_data[data_type].style ? option_data[data_type].style.value.after : \'\' }} ';
      html += '       </span>';
      html += '     </template>';
      html += '     <template v-else>';
      html += this.f_calculateCaptionHtml(false);
      html += wisdom_param_val_not_filled_span;
      html += '     </template>';
      html += '   </template>';
      // select radiogroup
      html += '   <template v-else-if="[\'radiogroup\', \'select\', \'object_list\'].indexOf(' + this.f_parameterString() + '.type) !== -1">';
      html += '     <template v-if="' + param_record_data + ' && ' + param_record_data + '.val && ' + param_record_data + '.val.label">';
      html += this.f_calculateCaptionHtml(true);
      html += '       <span :id="' + id_str_value_label + '" :style="f_getValueStyle()">';
      html += '         {{' + param_record_data + '.val.label }} {{ p_wdmrSettings.wisdom_param.user_style ? p_wdmrSettings.wisdom_param.style.value.after : option_data[data_type].style ? option_data[data_type].style.value.after : \'\' }} ';
      html += '       </span>';
      html += '     </template>';
      html += '     <template v-else>';
      html += this.f_calculateCaptionHtml(false);
      html += wisdom_param_val_not_filled_span;
      html += '     </template>';
      html += '   </template>';
      // checkbox. val includes list of value label objects [{'label': '', 'value': ''}]
      html += '   <template v-else-if="[\'checkbox\'].indexOf(' + this.f_parameterString() + '.type) !== -1">';
      html += '     <template v-if="' + param_record_data + ' && ' + param_record_data + '.val && ' + param_record_data + '.val.length > 0">';
      html += this.f_calculateCaptionHtml(true);
      html += '       <span :id="' + id_str_value_label + '" :style="f_getValueStyle()">';
      html += '         {{ f_getArrayLabelList(' + param_record_data + '.val) }} {{ p_wdmrSettings.wisdom_param.user_style ? p_wdmrSettings.wisdom_param.style.value.after : option_data[data_type].style ? option_data[data_type].style.value.after : \'\' }} ';
      html += '       </span>';
      html += '     </template>';
      html += '     <template v-else>';
      html += this.f_calculateCaptionHtml(false);
      html += wisdom_param_val_not_filled_span;
      html += '     </template>';
      html += '   </template>';
      html += '   <template v-else>';
      html += this.f_calculateCaptionHtml(false);
      html += '   </template>';
      //
      html += ' </div>';
      // html += '<div :id="\'popup_label_value_id_\' + f_getIndexList([' + this.option_location.join(', ') + '])" style="display: none; border: solid 1px black;">';
      /*
      html += '<b-row>';
      html += '<b-col cols="12">';
      html += '<b-button class="pull-right" variant="danger" @click="f_leftClickWdmr">-</b-button>';
      html += '</b-col>';
      html += '</b-row>';
      */
      // html += wisdom_param_html;
      // html += '</div>';
      html += '</template>';
      // 33333333333 end
      return html;
    },
    f_createWisdomParam: function (record_data_location) {
      let html = '';
      html += '<wisdom-param ';
      html += ' :p_aiRobotMatchParameterResult="p_aiRobotMatchParameterResult" ';
      html += ' :d_showModal="d_showModal" ';
      html += ' :d_patientSearchModalData="d_patientSearchModalData" ';
      html += ' :test_mode="test_mode" ';
      html += ' :option_data="option_data" ';
      html += ' :p_wdmrSettings="p_wdmrSettings" ';
      html += ' :external_patient_data="external_patient_data" ';
      html += ' :data="' + record_data_location + '" ';
      html += ' :option_param_mother="' + this.f_parameterMotherString() + '" ';
      html += ' :param="pr' + this.subbb_ind.toString() + '" ';
      html += ' :level="\'first\'" ';
      html += ' :data_type="data_type" ';
      html += ' :rule_engine="rule_engine" ';
      html += ' :query_list_type="query_list_type" ';
      html += ' :polestar="polestar" ';
      html += ' :query_list="query_list" ';
      html += ' :column_list="column_list" ';
      html += ' :param_change="param_change" ';
      html += ' :option_loc="[' + this.option_location.join(', ') + ']" ';
      html += ' :stage_data="stage_data" ';
      html += ' :key="\'param_order_wisdom-param-component-\' + pr' + this.subbb_ind.toString() + '_ind + \'-\' + pr' + this.subbb_ind.toString() + '" ';
      html += ' :wdm_version_wisdomera="wdm_version_wisdomera" ';
      html += ' :anatomy="' + this.f_wisdomParamAnatomy() + '" ';
      html += ' :wdm_name="option_data[data_type][\'name\']" ';
      html += ' :p_wdmrData="data.data" ';
      html += '  ></wisdom-param>';
      return html;
    },
    f_createWisdomParamModal: function (record_data_location) {
      let html = '';
      html += '<b-modal v-if="d_wisdomParamData.show" v-model="d_wisdomParamData.show" hide-footer :title="$t(\'wdm16.13180\')" centered header-bg-variant="info" header-text-variant="dark">';
      html += '<p class="my-4" style="word-wrap: break-word; min-height: 300px;">';
      html += '<wisdom-param ';
      html += ' :anatomy="d_wisdomParamData.anatomy" ';
      html += ' :data="d_wisdomParamData.data" ';
      html += ' :option_param_mother="d_wisdomParamData.option_param_mother" ';
      html += ' :param="d_wisdomParamData.param" ';
      html += ' :option_loc="d_wisdomParamData.option_loc" ';
      html += ' :p_aiRobotMatchParameterResult="p_aiRobotMatchParameterResult" ';
      html += ' :d_showModal="d_showModal" ';
      html += ' :d_patientSearchModalData="d_patientSearchModalData" ';
      html += ' :test_mode="test_mode" ';
      html += ' :option_data="option_data" ';
      html += ' :p_wdmrSettings="p_wdmrSettings" ';
      html += ' :external_patient_data="external_patient_data" ';
      html += ' :level="\'first\'" ';
      html += ' :data_type="data_type" ';
      html += ' :rule_engine="rule_engine" ';
      html += ' :query_list_type="query_list_type" ';
      html += ' :polestar="polestar" ';
      html += ' :query_list="query_list" ';
      html += ' :column_list="column_list" ';
      html += ' :param_change="param_change" ';
      html += ' :stage_data="stage_data" ';
      html += ' :wdm_version_wisdomera="wdm_version_wisdomera" ';
      html += ' :wdm_name="option_data[data_type][\'name\']" ';
      html += ' :p_wdmrData="data.data" ';
      html += '  ></wisdom-param>';
      html += '</p>';
      html += '</b-modal>';
      return html;
    },
    f_createWisdomParamPopup: function (record_data_location) {
      let html = '';
      let id_str = 'p_multiWdmrIndex === undefined ? \'wisdom_param_popup\' : p_multiWdmrIndex.toString() + \'_wisdom_param_popup\'';
      html += '<div :id="' + id_str + '" style="display: none;">';
      html += '<template v-if="d_wisdomParamData.show">';
      html += '<wisdom-param ';
      html += ' :anatomy="d_wisdomParamData.anatomy" ';
      html += ' :data="d_wisdomParamData.data" ';
      html += ' :option_param_mother="d_wisdomParamData.option_param_mother" ';
      html += ' :param="d_wisdomParamData.param" ';
      html += ' :option_loc="d_wisdomParamData.option_loc" ';
      html += ' :p_aiRobotMatchParameterResult="p_aiRobotMatchParameterResult" ';
      html += ' :d_showModal="d_showModal" ';
      html += ' :d_patientSearchModalData="d_patientSearchModalData" ';
      html += ' :test_mode="test_mode" ';
      html += ' :option_data="option_data" ';
      html += ' :p_wdmrSettings="p_wdmrSettings" ';
      html += ' :external_patient_data="external_patient_data" ';
      html += ' :level="\'first\'" ';
      html += ' :data_type="data_type" ';
      html += ' :rule_engine="rule_engine" ';
      html += ' :query_list_type="query_list_type" ';
      html += ' :polestar="polestar" ';
      html += ' :query_list="query_list" ';
      html += ' :column_list="column_list" ';
      html += ' :param_change="param_change" ';
      html += ' :stage_data="stage_data" ';
      html += ' :wdm_version_wisdomera="wdm_version_wisdomera" ';
      html += ' :wdm_name="option_data[data_type][\'name\']" ';
      html += ' :p_wdmrData="data.data" ';
      html += '  ></wisdom-param>';
      html += '</template>';
      html += '</div>';
      return html;
    },
    f_createListParameter: function (param_record_data) {
      let html = '';
      let ls_btn_drop_down = '';
      ls_btn_drop_down += '<template v-if="' + this.f_parameterString() + '.disabled === undefined || (' + this.f_parameterString() + '.disabled !== undefined && ' + this.f_parameterString() + '.disabled !== 1)">';
      ls_btn_drop_down += '<b-dropdown variant="secondary" size="sm" style="margin-left: 3px; margin-right: 3px; width: 50px;">';
      ls_btn_drop_down += '<b-dropdown-item @click="' + param_record_data + '.list.push(JSON.parse(JSON.stringify(' + param_record_data + '.list[ls' + this.subbb_ind.toString() + '_ind]))); param_change_func(); $forceUpdate();"><i class="fa fa-copy"></i>{{ $t(\'wdm16.7239\') }}</b-dropdown-item>';
      ls_btn_drop_down += '<b-dropdown-item @click="' + param_record_data + '.list.splice(ls' + this.subbb_ind.toString() + '_ind, 1); param_change_func(); $forceUpdate()"><i class="fa fa-trash"></i>{{ $t(\'wdm16.6765\') }}</b-dropdown-item>';
      ls_btn_drop_down += '<b-dropdown-item @click="f_listParamOrder(' + param_record_data + '.list, ls' + this.subbb_ind.toString() + '_ind, \'up\')"><i class="fa fa-angle-up"></i>{{ $t(\'wdm16.13004\') }}</b-dropdown-item>';
      ls_btn_drop_down += '<b-dropdown-item @click="f_listParamOrder(' + param_record_data + '.list, ls' + this.subbb_ind.toString() + '_ind, \'down\')"><i class="fa fa-angle-down"></i>{{ $t(\'wdm16.905\') }}</b-dropdown-item>';
      ls_btn_drop_down += '<b-dropdown-item @click="f_listParamOrder(' + param_record_data + '.list, ls' + this.subbb_ind.toString() + '_ind, \'top\')"><i class="fa fa-angle-double-up"></i>{{ $t(\'wdm16.13182\') }}</b-dropdown-item>';
      ls_btn_drop_down += '<b-dropdown-item @click="f_listParamOrder(' + param_record_data + '.list, ls' + this.subbb_ind.toString() + '_ind, \'bottom\')"><i class="fa fa-angle-double-down"></i>{{ $t(\'wdm16.13006\') }}</b-dropdown-item>';
      ls_btn_drop_down += '</b-dropdown>';
      /*
      ls_btn_drop_down += '<b-button size="md" variant="warning" style="margin-right: 5px; padding: 0px;" @click="' + this.f_parameterString() + '.view_mode && ' + this.f_parameterString() + '.view_mode === \'column_mode\' ? $delete(' + this.f_parameterString() + ', \'view_mode\') : ' + this.f_parameterString() + '.view_mode = \'column_mode\'; $forceUpdate(); param_change_func();">';
      ls_btn_drop_down += '<template v-if="' + this.f_parameterString() + '.view_mode && ' + this.f_parameterString() + '.view_mode === \'column_mode\'">';
      ls_btn_drop_down += '<img :src="d_icons[\'2956643\']" title="Kolon Modu" style="width: 2.2em;" />';
      ls_btn_drop_down += '</template>';
      ls_btn_drop_down += '<template v-else>';
      ls_btn_drop_down += '<img :src="d_icons[\'1319596\']" title="Normal Mod" style="width: 2.2em;" />';
      ls_btn_drop_down += '</template>';
      ls_btn_drop_down += '</b-button>';
      */
      ls_btn_drop_down += '</template>';
      let ls_card_key = '\'record_data_pr1_list_card-\' + pr' + this.subbb_ind.toString() + ' + \'_\' + ls' + this.subbb_ind.toString() + '_ind';
      html += '<template v-if="' + this.f_parameterString() + '.type === \'list_parameter\' && ' + param_record_data + ' && ' + param_record_data + '.list">';
      html += '   <template v-if="' + this.f_parameterString() + '.param_order && ' + this.f_parameterString() + '.param_order.length > 0">';
      html += '      <template v-if="p_wdmrSettings.wisdom_param.mode === 1 || p_wdmrSettings.wisdom_param.mode === 2">';
      html += '         <template v-for="(ls' + this.subbb_ind.toString() + ', ls' + this.subbb_ind.toString() + '_ind) in ' + param_record_data + '.list">';
      html += '            <b-card class="mb-1" no-body header-tag="header" footer-tag="footer" :key="' + ls_card_key + '" style="margin-left: 30px;">';
      html += '               <b-card-header header-bg-variant="secondary" class="p-1">';
      html += '                  <b-row style="cursor: pointer;">';
      html += '                     <b-col sm="12" lg="3">';
      html += '                     <small>{{ ls' + this.subbb_ind.toString() + '_ind + 1 }} - {{ ' + this.f_parameterString() + '.name.label.toLocaleUpperCase(\'TR\') }}</small>';
      html += '                     <span style="margin-left: 3px; margin-right: 3px; color: black;">{{ f_calculateListParameterNaming(ls' + this.subbb_ind.toString() + ', ' + this.f_parameterString() + ') }} </span>';
      html += ls_btn_drop_down;
      html += '                     </b-col>';
      html += '                     <b-col sm="12" lg="9" v-b-toggle="' + this.f_recordDataInfo() + ' + pr' + this.subbb_ind.toString() + ' + ls' + this.subbb_ind.toString() + '_ind">';
      html += '                     </b-col>';
      html += '                  </b-row>';
      html += '               </b-card-header>';
      html += '               <b-collapse :id="' + this.f_recordDataInfo() + ' + pr' + this.subbb_ind.toString() + ' + ls' + this.subbb_ind.toString() + '_ind" :visible="true">';
      // if we have deeper layer, we are creating new main_param_func
      if (this.subbb_ind < this.d_levelCount) {
        this.d_levelType = 'list';
        html += this.main_param_func();
        this.subbb_ind -= 1;
        this.option_location.splice(this.option_location.length - 1, 1);
      }
      html += '               </b-collapse>';
      html += '            </b-card>';
      html += '         </template>';
      html += '      </template>';
      html += '      <template v-else-if="p_wdmrSettings.wisdom_param.mode === 3">';
      html += '         <template v-for="(ls' + this.subbb_ind.toString() + ', ls' + this.subbb_ind.toString() + '_ind) in ' + param_record_data + '.list">';
      html += '            <b-row style="margin: 0px; border-bottom: 1px solid rgba(158, 193, 179, 0.3);">';
      html += '               <b-col sm="12" md="12">';
      html += '                  <small>{{ ls' + this.subbb_ind.toString() + '_ind + 1 }} - {{ ' + this.f_parameterString() + '.name.label.toLocaleUpperCase(\'TR\') }}</small>';
      // html += '                  <span style="margin-left: 3px; margin-right: 3px; color: black;">{{ f_calculateListParameterNaming(ls' + this.subbb_ind.toString() + ', ' + this.f_parameterString() + ') }} </span>';
      // html += '</br>';
      html += ls_btn_drop_down;
      html += '               </b-col>';
      html += '               <b-col sm="12" md="12">';
      // if we have deeper layer, we are creating new main_param_func
      if (this.subbb_ind < this.d_levelCount) {
        this.d_levelType = 'list';
        html += this.main_param_func();
        this.subbb_ind -= 1;
        this.option_location.splice(this.option_location.length - 1, 1);
      }
      html += '               </b-col>';
      html += '            </b-row>';
      html += '         </template>';
      html += '      </template>';
      html += '   </template>';
      html += '</template>';
      return html;
    },
    f_createObjectListOptions: function (param_record_data) {
      let html = '';
      // IF WE HAVE DEEPER PARAMETER UNDER THIS OBJECT LIST OPTIONS TYPE PARAMETER
      let deeper_if = this.f_parameterString() + '.param_order && ' + this.f_parameterString() + '.param_order.length > 0 && ' + param_record_data;
      let v_for_k = '(k' + this.subbb_ind.toString() + ', k' + this.subbb_ind.toString() + '_ind, k' + this.subbb_ind.toString() + '_order) in ' + param_record_data;
      let card_header_key = '\'record_data_pr' + this.subbb_ind.toString() + '_card-\' + k' + this.subbb_ind.toString() + '_ind + k' + this.subbb_ind.toString() + '_order + \'-\' + pr' + this.subbb_ind.toString();
      html += '<template v-if="' + this.f_parameterString() + '.type === \'object_list_options\'">';
      html += '   <template v-if="' + deeper_if + '">';
      html += '      <template v-if="p_wdmrSettings.wisdom_param.mode === 1 || p_wdmrSettings.wisdom_param.mode === 2">';
      html += '         <template v-for="' + v_for_k + '">';
      html += '            <b-card class="mb-1" no-body header-tag="header" footer-tag="footer" :key="' + card_header_key + '" style="margin-left: 30px;">';
      html += '               <b-card-header header-bg-variant="secondary" class="p-1">';
      html += '                  <b-row style="cursor: pointer;">';
      html += '                     <b-col cols="10" v-b-toggle="pr' + this.subbb_ind.toString() + ' + k' + this.subbb_ind.toString() + '_ind">';
      html += '                        <strong style="color: red;">{{ k' + this.subbb_ind.toString() + '.name.label }}</strong>';
      html += '                     </b-col>';
      html += '                     <b-col cols="2">';
      html += '                        <b-button :disabled="' + this.f_parameterString() + '.disabled === 1" size="sm" variant="danger" @click="$delete(' + param_record_data + ', k' + this.subbb_ind.toString() + '_ind); param_change_func(); $forceUpdate();"><i class="fa fa-trash"></i> </b-button>';
      html += '                     </b-col>';
      html += '                  </b-row>';
      html += '               </b-card-header>';
      html += '               <b-collapse :id="pr' + this.subbb_ind.toString() + ' + k' + this.subbb_ind.toString() + '_ind" v-if="k' + this.subbb_ind.toString() + '.detail" :visible="true">';
      // if we have deeper layer, we are creating new main_param_func
      if (this.subbb_ind < this.d_levelCount) {
        this.d_levelType = 'detail';
        html += this.main_param_func();
        this.subbb_ind -= 1;
        this.option_location.splice(this.option_location.length - 1, 1);
      }
      html += '               </b-collapse>';
      html += '            </b-card>';
      html += '         </template>';
      html += '      </template>'
      html += '      <template v-else-if="p_wdmrSettings.wisdom_param.mode === 3">';
      html += '         <template v-for="(k' + this.subbb_ind.toString() + ', k' + this.subbb_ind.toString() + '_ind, k' + this.subbb_ind.toString() + '_order) in ' + param_record_data + '">';
      html += '            <span> -> </span>';
      html += '            <b-button :disabled="' + this.f_parameterString() + '.disabled === 1" size="sm" variant="danger" @click="$delete(' + param_record_data + ', k' + this.subbb_ind.toString() + '_ind); param_change_func(); $forceUpdate();">';
      html += '               <i class="fa fa-trash"></i> ';
      html += '            </b-button> ';
      html += '            <i> {{ k' + this.subbb_ind.toString() + '.name.label }}: </i>';
      // if we have deeper layer, we are creating new main_param_func
      if (this.subbb_ind < this.d_levelCount) {
        this.d_levelType = 'detail';
        html += this.main_param_func();
        this.subbb_ind -= 1;
        this.option_location.splice(this.option_location.length - 1, 1);
      }
      html += '         </template>';
      html += '      </template>'
      html += '   </template>';
      html += '   <template v-else>';
      html += '      <template v-if="p_wdmrSettings.wisdom_param.mode === 1 || p_wdmrSettings.wisdom_param.mode === 2">';
      html += '         <b-row style="padding: 3px;">';
      html += '            <b-col cols="4">';
      html += '            </b-col>';
      html += '            <b-col cols="8">';
      html += '               <b-row style="padding: 3px;">';
      html += '                  <template v-for="(k' + this.subbb_ind.toString() + ', k' + this.subbb_ind.toString() + '_ind, k' + this.subbb_ind.toString() + '_order) in ' + param_record_data + '">';
      html += '                     <b-col cols="6">';
      html += '                        <b-button :disabled="' + this.f_parameterString() + '.disabled === 1" size="sm" variant="danger" @click="$delete(' + param_record_data + ', k' + this.subbb_ind.toString() + '_ind); param_change_func(); $forceUpdate();">';
      html += '                           <i class="fa fa-trash"></i> ';
      html += '                        </b-button> ';
      html += '                        {{ k' + this.subbb_ind.toString() + '.name.label }}';
      html += '                     </b-col>';
      html += '                  </template>';
      html += '               </b-row>';
      html += '            </b-col>';
      html += '         </b-row>';
      html += '      </template>';
      html += '      <template v-else-if="p_wdmrSettings.wisdom_param.mode === 3">';
      html += '         <template v-for="(k' + this.subbb_ind.toString() + ', k' + this.subbb_ind.toString() + '_ind, k' + this.subbb_ind.toString() + '_order) in ' + param_record_data + '">';
      html += '            <span> -> </span>';
      html += '            <b-button :disabled="' + this.f_parameterString() + '.disabled === 1" size="sm" variant="danger" @click="$delete(' + param_record_data + ', k' + this.subbb_ind.toString() + '_ind); param_change_func(); $forceUpdate();">';
      html += '               <i class="fa fa-trash"></i> ';
      html += '            </b-button> ';
      html += '            <i> {{ k' + this.subbb_ind.toString() + '.name.label }}: </i>';
      html += '         </template>';
      html += '      </template>';
      html += '   </template>';
      html += '</template>';
      return html;
    },
    f_getIndexList: function (index_list) {
      // console.log('f_getIndexList ', index_list);
      let str_index_list = '';
      for (let i in index_list) {
        str_index_list += index_list[i].type + '-wisdommodsiw-' + index_list[i].value;
        if (parseInt(i) !== index_list.length - 1) {
          str_index_list += '___';
        }
      }
      return str_index_list;
    },
    f_getOptionParamMother: function (option_loc) {
      let index_list = [];
      for (let i in option_loc) {
        if (['list', 'detail', 'anatomy'].indexOf(option_loc[i].type) === -1) {
          index_list.push(option_loc[i].value);
        }
      }
      let option_param_mother = ClsWdm.getParamMother(this.option_data[this.data_type], index_list);
      return option_param_mother;
    },
    f_WdmrKeyUp: function (e) {
      if (e.key === 'Shift') {
        this.d_lastKey = '';
      }
    },
    f_WdmrKeyDown: function (e) {
      // console.log('this.d_lastKey ', this.d_lastKey);
      // console.log('f_WdmrKeyDown e.key ', e.key);
      if (e.key === 'Shift') {
        this.d_lastKey = 'Shift';
      }
      if (this.d_lastKey === 'Shift' && e.key === 'Tab') {
        // console.log('Shift + Tab');
        if (this.d_wisdomParamData.show) {
          this.d_lastSecondOptionLoc = '';
          this.f_clear_d_wisdomParamData();
          this.f_goToNextParameter('back');
        }
      }
      if (e.key === 'Escape') {
        if (this.d_wisdomParamData.show) {
          this.d_lastSecondOptionLoc = '';
          this.f_clear_d_wisdomParamData();
        }
      } else if (e.key === 'Enter') {
        if (this.d_wisdomParamData.show) {
          let param_data = this.d_wisdomParamData.option_param_mother.parameters[this.d_wisdomParamData.param];
          if (param_data.type !== 'textarea') {
            this.f_clear_d_wisdomParamData();
            this.f_goToNextParameter('forward');
          }
        }
      } else if (e.key === 'Delete') {
        if (this.d_wisdomParamData.show) {
          let param_data = this.d_wisdomParamData.option_param_mother.parameters[this.d_wisdomParamData.param];
          if (['text', 'textarea'].indexOf(param_data) === -1) {
            if (['object_list_options', 'list_parameter'].indexOf(param_data.type) === -1) {
              if (['checkbox'].indexOf(param_data.type) !== -1) {
                this.d_wisdomParamData.data[this.d_wisdomParamData.param] = { 'val': [] };
              } else {
                this.d_wisdomParamData.data[this.d_wisdomParamData.param] = { 'val': '' };
              }
            } else {
              this.d_wisdomParamData.data[this.d_wisdomParamData.param] = {};
            }
          }
        }
      } else if (e.key === 'Tab') {
        if (this.d_wisdomParamData.show) {
          this.f_clear_d_wisdomParamData();
          this.f_goToNextParameter('forward');
        }
      }
      this.$forceUpdate();
    },
    f_clear_d_wisdomParamData: function () {
      this.d_wisdomParamData = {
        'anatomy': '',
        'data': '',
        'param': '',
        'show': false,
        'option_loc': '',
        'option_param_mother': ''
      };
      let id_str = this.p_multiWdmrIndex === undefined ? 'wisdom_param_popup' : this.p_multiWdmrIndex.toString() + '_wisdom_param_popup';
      let el = document.getElementById(id_str);
      if (el) {
        el.style.display = 'none';
      }
      this.$forceUpdate();
    },
    f_goToNextParameter: function (type) {
      let wdmr_location_id = this.p_multiWdmrIndex === undefined ? 'wdmr_location' : this.p_multiWdmrIndex.toString() + '_wdmr_location';
      // console.log(wdmr_location_id);
      let wdmr_location = document.getElementById(wdmr_location_id);
      // console.log(wdmr_location.childNodes);
      let id_list = [];
      this.f_getAllChildNodesEligibleIdList(wdmr_location.childNodes, id_list);
      // console.log(id_list);
      let current_id = this.p_multiWdmrIndex === undefined ? 'caption_label_value_id_' + this.f_getIndexList(this.d_lastOptionLoc) : this.p_multiWdmrIndex.toString() + '_caption_label_value_id_' + this.f_getIndexList(this.d_lastOptionLoc);
      let current_id_index = id_list.indexOf(current_id);
      // console.log('current_id_index =>>>> ', current_id_index);
      if (current_id_index > -1) {
        let id = '';
        if (type === 'forward' && id_list[current_id_index + 1]) {
          for (let i = current_id_index + 1; i < id_list.length; i++) {
            if (id_list[i].indexOf('caption_label_value_id_') !== -1) {
              let id = id_list[i].split('label_value_id_')[1];
              let param_data = this.f_getParamByID(id);
              if (['plain_text', 'object_list_options'].indexOf(param_data.type) === -1 && !param_data.disabled) {
                let res = this.f_getOptionLocRecordDataLocationByID(id);
                // element id structure => caption_label_value_id_group-wisdommodsiw-general___select-wisdommodsiw-imaging_group
                // console.log('next id ', element_id);
                let next_element = document.getElementById(id_list[i]);
                let rect = next_element.getBoundingClientRect();
                // console.log(rect);
                this.d_clickEvent.x = rect.x;
                this.d_clickEvent.y = rect.y;
                this.f_showWisdomParamPopup(res.option_loc, res.record_data_location, res.param, res.anatomy);
                break;
              }
            }
          }
        } else if (type === 'back' && id_list[current_id_index - 1]) {
          for (let i = current_id_index - 1; i >= 0; i--) {
            if (id_list[i].indexOf('caption_label_value_id_') !== -1) {
              let id = id_list[i].split('label_value_id_')[1];
              let param_data = this.f_getParamByID(id);
              if (['plain_text', 'object_list_options'].indexOf(param_data.type) === -1 && !param_data.disabled) {
                let res = this.f_getOptionLocRecordDataLocationByID(id);
                // element id structure => caption_label_value_id_group-wisdommodsiw-general___select-wisdommodsiw-imaging_group
                // console.log('next id ', element_id);
                let next_element = document.getElementById(id_list[i]);
                let rect = next_element.getBoundingClientRect();
                // console.log(rect);
                this.d_clickEvent.x = rect.x;
                this.d_clickEvent.y = rect.y;
                this.f_showWisdomParamPopup(res.option_loc, res.record_data_location, res.param, res.anatomy);
                break;
              }
            }
          }
        }
      }
    },
    f_getAllChildNodesEligibleIdList: function (childNodes, id_list) {
      for (let i in childNodes) {
        if (childNodes[i].id) {
          /*
          if (id_list.indexOf(childNodes[i].id) !== -1) {
            console.log(childNodes[i].id);
          }
          */
          id_list.push(childNodes[i].id);
        }
        if (childNodes[i].childNodes) {
          this.f_getAllChildNodesEligibleIdList(childNodes[i].childNodes, id_list);
        }
      }
    },
    f_getParamByID: function (id) {
      // console.log('id ', id);
      let x = id.split('___');
      let index_list = [];
      // console.log(x);
      for (let i in x) {
        let y = x[i].split('-wisdommodsiw-');
        if (['list', 'detail', 'anatomy'].indexOf(y[0]) === -1) {
          index_list.push(y[1]);
        }
      }
      // console.log(index_list);
      let param_data = ClsWdm.getParamDataWithIndexList(this.option_data[this.data_type], index_list);
      // console.log('param_data =>>>>>>>>>>>', param_data);
      return param_data;
    },
    f_getOptionLocRecordDataLocationByID: function (id) {
      // console.log('id ', id);
      let res = {
        'option_loc': [],
        'record_data_location': '',
        'anatomy': {},
        'param': '',
      };
      let x = id.split('___');
      let index_list = [];
      // console.log(x);
      let str = 'val = this.data.data';
      for (let i in x) {
        let y = x[i].split('-wisdommodsiw-');
        let y_type = y[0].toString();
        let y_value = y[1].toString();
        if (y_type === 'anatomy') {
          str += '["' + y_value + '"]';
          var val = '';
          // console.log(str);
          eval(str);
          res.anatomy = val;
          str += '["signs"]';
          let item = {
            'label': val.name.label,
            'value': y_value,
            'type': y_type
          };
          res.option_loc.push(item);
        } else if (y_type === 'list') {
          str += '["list"][' + y_value + ']';
          let item = {
            'label': 'list no: ' + y_value,
            'value': y_value,
            'type': y_type
          };
          res.option_loc.push(item);
        } else if (y_type === 'detail') {
          str += '["' + y_value + '"]';
          var val = '';
          eval(str);
          str += '["detail"]';
          let item = {
            'label': val.name.label,
            'value': y_value,
            'type': y_type
          };
          res.option_loc.push(item);
        } else {
          if (parseInt(i) === x.length - 1) {
            // console.log(str);
            var val = '';
            eval(str + ';');
            res.record_data_location = val;
          }
          str += '[\'' + y_value + '\']';
          index_list.push(y_value);
          // console.log('index_list ', index_list);
          let param_data = ClsWdm.getParamDataWithIndexList(this.option_data[this.data_type], index_list);
          // console.log(param_data);
          let item = {
            'label': param_data.name ? param_data.name.label : param_data.label,
            'value': y_value,
            'type': y_type
          };
          res.option_loc.push(item);
        }
      }
      res.param = index_list[index_list.length - 1];
      // console.log('res =>>>', res);
      return res;
    },
    f_showWisdomParamPopup: function (option_loc, record_data_location, param, anatomy) {
      this.d_lastOptionLoc = option_loc;
      if (!this.d_wisdomParamData.option_loc || (JSON.stringify(option_loc) !== JSON.stringify(this.d_wisdomParamData.option_loc))) {
        setTimeout(function () {
          this.d_wisdomParamData.param = param;
          this.d_wisdomParamData.data = record_data_location;
          this.d_wisdomParamData.option_loc = option_loc;
          this.d_wisdomParamData.anatomy = anatomy;
          this.d_wisdomParamData.option_param_mother = this.f_getOptionParamMother(option_loc);
          // let target_el_id = 'caption_label_value_id_' + this.f_getIndexList(option_loc);
          // let target_el = document.getElementById(target_el_id);
          // console.log('target_el ', target_el);
          // let off = target_el.getBoundingClientRect();
          // let x = off.top - 40;
          // let y = off.left;
          let x = this.d_clickEvent.x;
          let y = this.d_clickEvent.y;
          let id_str = this.p_multiWdmrIndex === undefined ? 'wisdom_param_popup' : this.p_multiWdmrIndex.toString() + '_wisdom_param_popup';
          let doc = document.getElementById(id_str);
          doc.classList.add('popup_wisdom_param');
          doc.style.display = '';
          doc.style.top = (y - 30).toString() + 'px';
          let left = x + 20;
          if (left < 0) {
            left = 0;
          }
          doc.style.left = (left).toString() + 'px';
          // console.log(doc);
          /*
            let wisdom_param_doc = document.getElementById(wisdom_param_input_id);
            console.log(wisdom_param_doc);
            if (wisdom_param_doc) {
              wisdom_param_doc.focus();
            }
          */
          this.d_wisdomParamData.show = true;
          this.$forceUpdate();
        }.bind(this), 50);
      }
    },
    f_leftClickWdmr: function (e) {
      // console.log('f_leftClickWdmr e: ', e);
      // console.log('e.clientX: ', e.clientX);
      // console.log('e.clientY: ', e.clientY);
      if (e.detail > 0) {
        this.d_clickEvent.x = e.clientX;
        this.d_clickEvent.y = e.clientY;
        let popup_det = false;
        for (let i in e.path) {
          // console.log('e.path[i].id ', e.path[i].id);
          let id_str = this.p_multiWdmrIndex === undefined ? 'wisdom_param_popup' : this.p_multiWdmrIndex.toString() + '_wisdom_param_popup';
          if (e.path[i].id && e.path[i].id.indexOf(id_str) !== -1) {
            popup_det = true;
            break;
          }
        }
        // console.log('popup_det: ', popup_det);
        if (popup_det) {
          // here we control for param data structure by clicked id
          let wisdom_param_input_id = '';
          for (let i in e.path) {
            // console.log('e.path[i].id ', e.path[i].id);
            if (e.path[i].id && e.path[i].id.indexOf('wisdom_param_input_') !== -1) {
              wisdom_param_input_id = e.path[i].id;
              break;
            }
          }
          // console.log('wisdom_param_input_id ', wisdom_param_input_id);
          if (wisdom_param_input_id) {
            let id = wisdom_param_input_id.split('wisdom_param_input_')[1];
            let param_data = this.f_getParamByID(id);
            if (param_data && ['select', 'object_list'].indexOf(param_data.type) !== -1) {
              this.f_clear_d_wisdomParamData();
              this.f_goToNextParameter('forward');
            }
            if (param_data && ['radiogroup'].indexOf(param_data.type) !== -1) {
              if (JSON.stringify(this.d_lastSecondOptionLoc) !== JSON.stringify(this.d_lastOptionLoc)) {
                this.d_lastSecondOptionLoc = JSON.parse(JSON.stringify(this.d_lastOptionLoc));
                setTimeout(function () {
                  this.f_clear_d_wisdomParamData();
                  this.f_goToNextParameter('forward');
                }.bind(this), 10)
              }
            }
          }
        } else {
          this.d_lastSecondOptionLoc = '';
          // console.log(this.d_wisdomParamData);
          if (this.d_wisdomParamData.show) {
            this.f_clear_d_wisdomParamData();
          }
        }
        this.$forceUpdate();
      }
    },
    f_getArrayLabelList: function (val_list) {
      let x = '';
      for (let i in val_list) {
        x += val_list[i].label;
        if (parseInt(i) !== val_list.length - 1) {
          x += ', ';
        }
      }
      return x;
    },
    f_showGroup: function (group) {
      if (!this.d_groupShowList[group].show_timer) {
        this.d_groupShowList[group].show_timer = true;
        this.$forceUpdate();
        setTimeout(function () {
          this.d_groupShowList[group].show ? this.d_groupShowList[group].show = false : this.d_groupShowList[group].show = true;
          this.$forceUpdate();
        }.bind(this), 100)
        setTimeout(function () {
          this.d_groupShowList[group].show_timer = false;
          this.$forceUpdate();
        }.bind(this), 500)
      }
    },
    f_calculateListParameterNaming: function (data, list_param_data) {
      // console.log(data);
      let tmp_wdm = {
        'param_group': {
          'param_order': ['general']
        },
        'general': {
          'param_order': [],
          'parameters': {}
        }
      };
      let tmp_wdmr = { 'data': { 'general': {} } };
      for (let i in list_param_data.param_order) {
        let pr = list_param_data.param_order[i];
        if (['object_list', 'list_parameter'].indexOf(list_param_data.parameters[pr].type) === -1) {
          if (list_param_data.parameters[pr].type !== 'object_list_options') {
            tmp_wdmr.data.general[pr] = data[pr];
          } else {
            let detailed_data = {};
            for (let d in data[pr]) {
              let x = { 'name': data[pr][d].name, 'detail': {} };
              detailed_data[d] = x;
            }
            tmp_wdmr.data.general[pr] = detailed_data;
          }
          tmp_wdm['general']['parameters'][pr] = list_param_data.parameters[pr];
          tmp_wdm['general']['param_order'].push(pr);
        }
      }
      let return_data = ClsWdmrForever.turn(tmp_wdmr, tmp_wdm, ['name']);
      // console.log(return_data);
      if (return_data.name) {
        return ' " ' + return_data.name + ' " ';
      } else {
        return '';
      }
    },
    f_wisdomParamAnatomy: function () {
      if (this.is_anatomy) {
        return 'ana.name';
      } else {
        return "{}";
      }
    },
    f_anatomyRegion: function () {
      if (this.is_anatomy) {
        return 'ana.name';
      } else {
        return "\'\'";
      }
    },
    f_recordData: function () {
      if (this.subbb_ind === 1) {
        if (this.is_anatomy) {
          return "ana.signs";
        } else {
          return "data['data'][group]";
        }
      } else if (this.subbb_ind > 1) {
        if (this.d_levelType === 'detail') {
          return "k" + (this.subbb_ind - 1).toString() + ".detail";
        } else if (this.d_levelType === 'list') {
          return "ls" + (this.subbb_ind - 1).toString();
        }
      }
    },
    f_recordDataInfo: function () {
      if (this.subbb_ind === 1) {
        if (this.is_anatomy) {
          return "ana.name.value";
        } else {
          return "group";
        }
      } else if (this.subbb_ind > 1) {
        return "ls" + (this.subbb_ind).toString() + "_ind";
        // will be check in future
        // if (this.d_levelType === 'detail') {
        //   return "k" + (this.subbb_ind).toString() + "_order";
        // } else if (this.d_levelType === 'list') {
        //   return "ls" + (this.subbb_ind).toString() + "_ind";
        // }
      }
    },
    f_recordDataObjectListOptions: function () {
      if (this.subbb_ind === 1) {
        return "data['data'][group]";
      } else if (this.subbb_ind === 2) {}
    },
    f_paramorderString: function () {
      if (this.subbb_ind === 1) {
        return 'option_data[data_type][group].param_order';
      } else if (this.subbb_ind > 1) {
        let str = 'option_data[data_type][group]';
        for (let i = 1; i < this.subbb_ind; i++) {
          str += '.parameters[pr' + i.toString() + ']';
        }
        return str + '.param_order';
      }
    },
    f_parameterString: function () {
      let str = 'option_data[data_type][group]';
      for (let i = 1; i <= this.subbb_ind; i++) {
        str += '.parameters[pr' + i.toString() + ']';
      }
      return str;
    },
    f_calculateParamLocation_detailed: function () {
      let str = '';
      if (this.subbb_ind === 1) {
        this.option_location = [];
        let label_str = 'option_data[data_type][group].parameters[pr1]';
        str = '{\'label\': option_data[data_type].param_group.parameters[group].label, \'value\': group, \'type\': \'group\' }';
        if (this.is_anatomy) {
          str += ", {'label': ana.name.label, 'value': ana_ind, 'type': 'anatomy'}";
        }
        this.option_location.push(str);
        str = '{\'label\': ' + label_str + '.name.label, \'value\': ' + label_str + '.name.value, \'type\': ' + label_str + '.type }';
        this.option_location.push(str);
      } else {
        if (this.d_levelType === 'detail') {
          str += "{'label': k" + (this.subbb_ind - 1).toString() + ".name.label, 'value': k" + (this.subbb_ind - 1).toString() + "_ind, 'type': 'detail'}, ";
        } else if (this.d_levelType === 'list') {
          str += "{'label': 'list no: ' + ls" + (this.subbb_ind - 1).toString() + "_ind.toString(), 'value': ls" + (this.subbb_ind - 1).toString() + "_ind.toString(), 'type': 'list'}, ";
        }
        let label_str = 'option_data[data_type][group]';
        for (let a = 1; a <= this.subbb_ind; a++) {
          label_str += '.parameters[pr' + a.toString() + ']';
        }
        str += '{\'label\': ' + label_str + '.name.label, \'value\': ' + label_str + '.name.value, \'type\': ' + label_str + '.type }';
        if (this.subbb_ind === this.option_location.length) {
          this.option_location.push(str);
        } else if (this.subbb_ind === this.option_location.length - 1) {
          this.option_location[this.option_location.length - 1] = str;
        }
        /*
        if (this.d_levelType === 'list') {
          console.log(JSON.stringify(this.option_location));
        }
        */
      }
    },
    f_calculateParamLocation: function () {
      let str = '[{\'label\': option_data[data_type].param_group.parameters[group].label, \'value\': group, \'type\': \'group\' }, ';
      for (let i = 1; i <= this.subbb_ind; i++) {
        let label_str = 'option_data[data_type][group]';
        for (let a = 1; a <= i; a++) {
          label_str += '.parameters[pr' + a.toString() + ']';
        }
        str += '{\'label\': ' + label_str + '.name.label, \'value\': ' + label_str + '.name.value, \'type\': ' + label_str + '.type }';
        if (i !== this.subbb_ind) {
          str += ', ';
        } else {
          str += ']';
        }
      }
      return str;
    },
    f_listParamOrder: function (list, index, direction) {
      // console.log('list:', list, 'index:', index, 'direction:', direction);
      let index_data = list[index];
      let count = list.length;
      let friend = '';
      if (direction === 'up') {
        if (index !== 0) {
          friend = list[index - 1];
          list[index - 1] = index_data;
          list[index] = friend;
        }
      } else if (direction === 'down') {
        if (index !== count - 1) {
          friend = list[index + 1];
          list[index + 1] = index_data;
          list[index] = friend;
        }
      } else if (direction === 'top') {
        list.splice(index, 1);
        list.splice(0, 0, index_data);
      } else if (direction === 'bottom') {
        list.splice(index, 1);
        list.push(index_data);
      }
      this.$forceUpdate();
    },
    f_parameterMotherString: function () {
      if (this.subbb_ind === 1) {
        return 'option_data[data_type][group]';
      } else if (this.subbb_ind > 1) {
        let str = 'option_data[data_type][group]';
        for (let i = 1; i < this.subbb_ind; i++) {
          str += '.parameters[pr' + i.toString() + ']';
        }
        return str;
      }
    },
    f_anatomySchema: function () {
      let html = '';
      return html;
    },
    param_change_func: function () {
      if (this.param_change.id) {
        this.param_change.id = 0;
      } else {
        this.param_change.id = 1;
      }
    },
    calculate_list_pr_loc: function (data_param_loc) {
      return data_param_loc.split('..')[0];
    },
    calc_anatomy (data_record_loc) {
      let arr = data_record_loc.split('.');
      let str = arr[0] + '.' + arr[1] + '.name';
      // console.log(str);
      return str;
    },
    copy_anatomy_data_to_this_anatomy (copy_ana_ind, ana_ind) {
      this.data['data'].anatomy[ana_ind].signs = JSON.parse(JSON.stringify(this.data['data'].anatomy[copy_ana_ind].signs));
      this.$forceUpdate();
    },
    cal_param_loc: function (type, loc) {
      let data = '';
      if (type === 'code') {
        if (this.subbb_ind === 1) {
          data = this.option_data[this.data_type][this.subbb[0]];
        } else if (this.subbb_ind === 2) {
          data = this.option_data[this.data_type][this.subbb[0]].parameters[this.subbb[1]];
        } else if (this.subbb_ind === 3) {
          data = this.option_data[this.data_type][this.subbb[0]].parameters[this.subbb[1]].parameters[this.subbb[2]];
        }
        if (loc === 'options') {
          return data.options;
        } else {
          return data;
        }
      } else if (type === 'text') {
        if (loc === 'param_data') {
          let str = 'option_data.' + this.data_type;
          for (let i in this.subbb) {
            str += '.' + this.subbb[i];
            if (parseInt(i) !== this.subbb.length - 1) {
              str += '.parameters';
            }
          }
          return str;
        } else if (loc === 'location_parameters') {
          let str = 'option_data.' + this.data_type;
          for (let i = 0; i < this.subbb.length - 1; i++) {
            str += '.' + this.subbb[i];
            if (i !== this.subbb.length - 2) {
              str += '.parameters';
            }
          }
          return str;
        }
      }
    },
    f_favoriteShowControl: function (param_group) {
      if (this.p_wdmrSettings.favorite_show_mode) {
        if (this.user_favorite_parameter_list && this.user_favorite_parameter_list.length > 0) {
          let det = false;
          for (let i in this.user_favorite_parameter_list) {
            if (this.user_favorite_parameter_list[i][0] === param_group) {
              det = true;
              break;
            }
          }
          // console.log(param_group, det);
          if (!det) {
            return false;
          }
        } else {
          return false;
        }
      }
      return true;
    },
    f_controlParamGroup: function (param_group) {
      if (this.use_parameter_list) {
        if (this.parameter_list && this.parameter_list.length > 0) {
          let x = { 'y': false };
          this.f_controlParameterInThisGroup(this.option_data[this.data_type][param_group]['parameters'], x);
          if (x.y) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    f_controlParameterInThisGroup: function (parameters, x) {
      if (!x.y) {
        for (let p in parameters) {
          if (this.parameter_list.indexOf(p) !== -1) {
            x.y = true;
          }
          if (x.y) {
            break;
          } else {
            if (parameters['parameters']) {
              this.f_controlParameterInThisGroup(parameters['parameters'], x);
            }
          }
        }
      }
    },
    cal_data_loc: function (type, loc, list_param, list_type, anatomy) {
      if (type === 'text') {
        if (loc === 'param') {
          let str = 'data';
          for (let i in this.subbb) {
            if (this.detail_pr.indexOf(this.subbb[i]) === -1 && this.list_pr.indexOf(this.subbb[i]) === -1) {
              str += '.' + this.subbb[i];
              if (this.subbb[i] === 'anatomy') {
                str += '[\'' + anatomy + '\'].signs';
              }
            } else if (this.detail_pr.indexOf(this.subbb[i]) !== -1) {
              str += '.' + this.subbb[i] + '.' + this.detail_id + '.detail';
            } else if (this.list_pr.indexOf(this.subbb[i]) !== -1) {
              // console.log('lsitttttttttt....', this.subbb[i]);
              str += '.' + this.subbb[i] + '.list[' + this.list_id + ']';
            }
          }
          // console.log('cal_data_loc..param......', str);
          return str;
        } else if (loc === 'record') {
          if (list_type === 'detail') {
            // console.log('cal_data_loc..detail..subbb', this.subbb);
          }
          let str = 'data';
          for (let i = 0; i < this.subbb.length - 1; i++) {
            if (this.detail_pr.indexOf(this.subbb[i]) === -1 && this.list_pr.indexOf(this.subbb[i]) === -1) {
              str += '.' + this.subbb[i];
              if (this.subbb[i] === 'anatomy') {
                str += '[\'' + anatomy + '\'].signs';
              }
            } else if (this.detail_pr.indexOf(this.subbb[i]) !== -1) {
              str += '.' + this.subbb[i] + '.' + this.detail_id + '.detail';
            } else if (this.list_pr.indexOf(this.subbb[i]) !== -1) {
              // console.log('lsitttttttttt....', this.subbb[i]);
              str += '.' + this.subbb[i] + '.list[' + this.list_id + ']';
            }
          }
          // console.log('cal_data_loc...record......', str);
          return str;
        }
      } else if (type === 'code') {
        if (anatomy) {
          // console.log('this.subbb.....', this.subbb);
          // console.log('this.subbb_ind.....', this.subbb_ind);
          if (this.subbb_ind === 1) {
            return this.data[this.subbb[0]][anatomy].signs[this.subbb[1]];
          } else if (this.subbb_ind === 2) {
            if (this.list_pr.indexOf(this.subbb[1]) !== -1 && this.detail_pr.indexOf(this.subbb[2]) !== -1) {
              return this.data[this.subbb[0]][anatomy].signs[this.subbb[1]].list[this.list_id][this.subbb[2]];
            }
          } else if (this.subbb_ind === 3) {
            return this.data[this.subbb[0]][anatomy].signs[this.subbb[1]][this.subbb[2]][this.subbb[3]];
          }
        } else {
          if (this.subbb_ind === 1) {
            return this.data[this.subbb[0]][this.subbb[1]];
          } else if (this.subbb_ind === 2) {
            return this.data[this.subbb[0]][this.subbb[1]][this.subbb[2]];
          } else if (this.subbb_ind === 3) {
            return this.data[this.subbb[0]][this.subbb[1]][this.subbb[2]][this.subbb[3]];
          }
        }
      }
    },
    f_controlDocumentRect: function () {
      if (document.getElementById('xxx').getBoundingClientRect().top < 300) {
        return true;
      } else {
        return false;
      }
    },
    create_btn_group: function (vif = '', caption = '', btn_list) {
      let html = '';
      html += '<b-dropdown ' + vif + ' left variant="primary" text="' + caption + '">';
      html += btn_list;
      html += '</b-dropdown>';
      return html;
    },
    delete_obj: function (data, obj) {
      this.$delete(data, obj);
      this.render_template();
    },
    delete_anatomy: function (data, ana_ind) {
      this.$delete(data, ana_ind);
      this.render_template();
    },
    get_schema_list: function () {
      OptionService.get_schema_list({ schema_type: this.data_type, schema_scope: this.schema_scope })
        .then(resp => {
          // console.log(resp.data);
          if (resp.data.results.length > 0) {
            this.schema_list = resp.data.results;
          }
        });
    },
    get_schema_data: function () {
      OptionService.get_schema_data(this.selected_schema)
        .then(resp => {
          this.selected_schema_data = resp.data;
          for (let i in this.data) {
            this.$delete(this.data, this.data[i]);
          }
          for (let i in this.selected_schema_data.data) {
            this.data[i] = this.selected_schema_data.data[i];
          }
          this.render_template();
        });
    },
    save_schema: function (x) {
      let schema_data = {};
      if (x === 'new') {
        schema_data = { data: {} };
        schema_data.data = JSON.parse(JSON.stringify(this.data));
        schema_data.label = this.schema_caption;
        schema_data.schema_type = this.data_type;
        schema_data.scope = this.schema_scope;
      } else if (x === 'edit') {
        schema_data = this.selected_schema_data;
        schema_data.data = JSON.parse(JSON.stringify(this.data));
      }
      OptionService.save_schema(schema_data)
        .then(resp => {
          // console.log(resp.data);
          alert(this.$t('wdm16.12743'));
        });
    },
    option_data_save: function () {
      OptionService.save_schema(this.data)
        .then(resp => {
          // console.log(resp.data.results);
          alert(this.$t('wdm16.12661'));
        });
    },
    SearchDiagnosisOptions: function (search) {
      OptionService.search('icd10', { 'search': search })
        .then(resp => {
          this.diagnosis_options = resp.data.results;
        });
    },
    AddToThisQuery: function (parameter_rule, query_ind, param, type) {
      if (type === 'all') {
        for (let g in this.option_data[this.data_type].param_group.parameters) {
          for (let p in this.option_data[this.data_type][g].parameters) {
            let param_data = this.option_data[this.data_type][g].parameters[p];
            if (param_data.rule && param_data.rule.val_list.length > 0) {
              this.this_query_func(param_data.rule, query_ind, p);
            }
          }
        }
      } else if (type === 'one') {
        this.this_query_func(parameter_rule, query_ind, param);
      }
    },
    this_query_func: function (parameter_rule, query_ind, param) {
      let data = JSON.parse(JSON.stringify(parameter_rule));
      this.query_list[query_ind].query_design_order.splice(this.query_list[query_ind].query_design_order.length - 1, 1);
      this.query_list[query_ind].query_design_order.push('and');
      this.query_list[query_ind].query_design_order.push((this.query_list[query_ind].fields.length).toString());
      this.query_list[query_ind].query_design_order.push(')');
      this.query_list[query_ind].query_design = '';
      for (let i in this.query_list[query_ind].query_design_order) {
        if (['(', ')', 'and', 'or'].indexOf(this.query_list[query_ind].query_design_order[i]) === -1) {
          this.query_list[query_ind].query_design += ' (-' + this.query_list[query_ind].query_design_order[i] + '-) ';
        } else {
          this.query_list[query_ind].query_design += this.query_list[query_ind].query_design_order[i];
        }
      }
      this.query_list[query_ind].fields.push({ field: data.field, value_type: data.value_type, values: data.val_list, operation: data.op, captions: data.labels });
      this.calculate_query();
    },
    AddNewQuery: function (parameter_rule, param, type) {
      if (type === 'all') {
        for (let g in this.option_data[this.data_type].param_group.parameters) {
          for (let p in this.option_data[this.data_type][g].parameters) {
            let param_data = this.option_data[this.data_type][g].parameters[p];
            if (param_data.rule && param_data.rule.val_list.length > 0) {
              this.query_func(param_data.rule, p);
            }
          }
        }
      } else if (type === 'one') {
        this.query_func(parameter_rule, param);
      }
    },
    query_func: function (parameter_rule, param) {
      let data = JSON.parse(JSON.stringify(parameter_rule));
      let query = {};
      query = { query: '', fields: [], query_design_order: ['(', '0', ')'], query_design: '(-0-)', date_var: data.date_var, name: this.data_type, type: data.type, data_type: data.data_type, time_of_value: data.time_of_value, buck: data.buck, significant: data.significant };
      if (data.days) {
        query.days = data.days;
      }
      if (data.time_of_value === 'last_value') {
        query.order_by = 'ORDER BY date DESC';
      }
      query.fields.push({ field: data.field, value_type: data.value_type, values: data.val_list, operation: data.op, captions: data.labels });
      this.query_list.push(query);
      this.calculate_query();
    },
    calculate_query: function () {
      for (let q in this.query_list) {
        let query = this.query_list[q];
        let query_design = JSON.parse(JSON.stringify(query.query_design));
        for (let f in query.fields) {
          let query_design_ind = '-' + f.toString() + '-';
          let where_list = [];
          let x = '';
          if (query.fields[f].operation === '=') {
            x = query.fields[f].field + " in ['" + query.fields[f].values.join("','") + "']";
          } else if (query.fields[f].operation === '<>') {
            x = query.fields[f].field + " not in ['" + query.fields[f].values.join("','") + "']";
          } else {
            for (let val in query.fields[f].values) {
              let k = query.fields[f].field + ' ' + query.fields[f].operation + ' ' + query.fields[f].values[val];
              where_list.push(k);
            }
            x = where_list.join(' OR ');
          }
          query_design = query_design.replace(query_design_ind, x);
        }
        query.query = query_design;
      }
    },
    get_wisdom_data: function () {
      if (this.data_type) {
        let data = { option_list: [] };
        data.option_list.push(this.data_type);
        OptionService.get_list(data)
          .then(resp => {
            let pre_data = {};
            this.option_data = resp.data.results;
            for (let i in this.option_data[this.data_type].param_group.param_order) {
              pre_data[this.option_data[this.data_type].param_group.param_order[i]] = {};
            }
            this.data = pre_data;
            this.$forceUpdate();
          });
      }
    },
    calculate_label_caption: function (param_data, val) {
      if (param_data.options) {
        for (let i in param_data.options) {
          if (param_data.options[i].value === val) {
            return param_data.options[i].label;
          }
        }
      } else {
        return val;
      }
    },
    f_getOptionLocValues: function (option_loc) {
      /*
        option_loc = [{label: "Genel parametreler", value: "general", type: "group"}, ....]
        option_loc_only_values = ['general', 'paramxxxxx', ...]
      */
      let option_loc_only_values = [];
      for (let i in option_loc) {
        if (['list', 'detail', 'anatomy'].indexOf(option_loc[i].type) === -1) {
          option_loc_only_values.push(option_loc[i].value);
        }
      }
      return option_loc_only_values;
    },
    f_optionLocUserFavoriteControl: function (option_loc) {
      /*
        option_loc = [{label: "Genel parametreler", value: "general", type: "group"}, ....]
        option_loc_only_values = ['general', 'paramxxxxx', ...]
      */
      let option_loc_only_values = this.f_getOptionLocValues(option_loc);
      if (this.user_favorite_parameter_list && this.user_favorite_parameter_list.length > 0) {
        let det = false;
        for (let i in this.user_favorite_parameter_list) {
          if (this.user_favorite_parameter_list[i].join(',') === option_loc_only_values.join(',')) {
            det = true;
            break;
          }
        }
        if (!det) {
          return false;
        }
      } else {
        return false;
      }
      return true;
    },
    f_optionLocUserDontShowCaption: function (option_loc) {
      /*
        option_loc = [{label: "Genel parametreler", value: "general", type: "group"}, ....]
        option_loc_only_values = ['general', 'paramxxxxx', ...]
      */
      let option_loc_only_values = this.f_getOptionLocValues(option_loc);
      if (this.dont_show_caption_index_data && this.dont_show_caption_index_data.length > 0) {
        let det = false;
        for (let i in this.dont_show_caption_index_data) {
          if (this.dont_show_caption_index_data[i].join(',') === option_loc_only_values.join(',')) {
            det = true;
            break;
          }
        }
        if (!det) {
          return true;
        }
      } else {
        return true;
      }
      return false;
    },
    f_calculateCaptionHtml: function (has_param_data = false) {
      let id_str = 'p_multiWdmrIndex === undefined ? \'caption_label_value_id_\' + f_getIndexList([' + this.option_location.join(', ') + ']) : p_multiWdmrIndex.toString() + \'_caption_label_value_id_\' + f_getIndexList([' + this.option_location.join(', ') + '])';
      if (has_param_data) {
        return '<strong :id="' + id_str + '" :style="f_getCaptionStyle()"><template v-if="f_optionLocUserDontShowCaption([' + this.option_location.join(', ') + '])"> {{ ' + this.f_parameterString() + '.name.label }} {{ p_wdmrSettings.wisdom_param.user_style ? p_wdmrSettings.wisdom_param.style.caption.after : option_data[data_type].style ? option_data[data_type].style.caption.after : \'\' }} </template></strong>'
      } else {
        return '<strong :id="' + id_str + '" :style="f_getCaptionStyle()"> {{ ' + this.f_parameterString() + '.name.label }} {{ p_wdmrSettings.wisdom_param.user_style ? p_wdmrSettings.wisdom_param.style.caption.after : option_data[data_type].style ? option_data[data_type].style.caption.after : \'\' }} </strong>'
      }
    },
    f_checkFriendMath: function (param_total_value, math_operator) {
      param_total_value = parseFloat(param_total_value);
      let number_1 = parseFloat(math_operator.number_1);
      if (math_operator.operator === 'between' || math_operator.operator === 'not_between') {
        let number_2 = parseFloat(math_operator.number_2);
        let min = Math.min(number_1, number_2);
        let max = Math.max(number_1, number_2);
        if (math_operator.operator === 'between') {
          return param_total_value > min && param_total_value < max;
        } else {
          return param_total_value < min || param_total_value > max;
        }
      } else {
        if ((math_operator.operator === '>' && param_total_value > number_1) || (math_operator.operator === '<' && param_total_value < number_1) || (math_operator.operator === '===' && param_total_value === number_1) || (math_operator.operator === '!==' && param_total_value !== number_1)) {
          return true
        }
        return false;
      }
    },
    calculate_if: function (recording_data_region, param_ind, recording_param_data, location_parameters, mother = "", anatomy_region, param_group, option_loc) {
      /*      
            console.log('option_loc', option_loc);
            console.log('param_ind', param_ind);
            console.log('recording_data_region', recording_data_region);
            console.log('recording_param_data', recording_param_data);
            console.log('location_parameters', location_parameters);
            console.log('mother', mother);
            console.log('anatomy_region', anatomy_region);
            
      */

      /* rule enginede ve polestarda kolon seçimi için kullanıldığından tüm parametreleri gösteriyoruz.
      if (this.rule_engine || this.polestar) {
        return true;
      }
      */
      // özel bir parametrenin sadece gösterilmesini istyorsak bu parametre listesinden yönetiyoruz.
      if (!recording_data_region) {
        return false;
      }
      if (this.p_wdmrSettings.favorite_show_mode) {
        let res = this.f_optionLocUserFavoriteControl(option_loc);
        // console.log(res);
        if (!res) {
          return false;
        }
      }
      // favorite_show_mode false ise diğer analizlere ayrıca devam ediyoruz.
      // console.log('this.parameter_list', this.parameter_list);
      if (this.parameter_list && this.parameter_list.length > 0 && this.use_parameter_list && this.parameter_list.indexOf(param_ind) === -1) {
        return false;
      }
      let ret = false;
      if (recording_param_data.show === "no") {
        return ret;
      }
      if (recording_param_data.if) {
        // friend parameters control
        if (recording_param_data.if.friend && Object.keys(recording_param_data.if.friend).length > 0) {
          let friend_data = recording_param_data.if.friend;
          let friend_type = recording_param_data.if.friend_type; // all_friend_match, any_one_of_friend_match
          // Our if.friend_type is not included old records. So we give manually all_friend_match at that time
          if (!friend_type) {
            friend_type = 'all_friend_match';
          }
          // friend_data structure:
          /*
          EXAMPLE: Two forms of friend_data
          friend_data = {
            "material": {
                "parent_param": "yes",
                "name": {
                    "value": "material",
                    "label": "materyal"
                },
                "operation": {
                    "or_options": [
                        "biopsy"
                    ]
                }
          }
          friend_data = {
            "material": {
                "name": {
                  "value": "material",
                  "label": "materyal"
                },
                "operation": {
                  "or_options": [
                      "biopsy"
                  ]
                },
                "index_list": [
                  "general",
                  "neoadjuvant_treatment_status"
              ]
          }
          */
          for (let i in friend_data) {
            let target_data = {};
            // parent param means, this param and the friend param are located in the same layer
            if (friend_data[i].parent_param === "yes") {
              target_data = recording_data_region;
            } else {
              try {
                // example of friend_data[i]['index_list'][0] => this.data.data.general
                target_data = this.data.data[friend_data[i]['index_list'][0]];
              } catch (err) {
                if (friend_type === 'all_friend_match') {
                  return false;
                }
              }
            }
            // parametre seçili değilse ya da parametre seçili olup val değeri doldurulmadıysa
            if (!target_data[i] || (target_data[i] && !target_data[i]['val'])) {
              if (friend_type === 'all_friend_match') {
                if (target_data[param_ind]) {
                  this.$delete(target_data, param_ind);
                }
                return false;
              }
            } else { // parametrede val değeri dolu
              if (friend_data[i].operation && Object.keys(friend_data[i].operation).length > 0) {
                for (let k in friend_data[i].operation) {
                  // console.log(target_data[i]['val']);
                  // console.log(typeof target_data[i]['val']);
                  if (k === "or_options") {
                    if (typeof target_data[i]['val'] === 'object') {
                      if (target_data[i]['val'].value) {
                        if (friend_data[i].operation[k].indexOf(target_data[i]['val'].value) === -1) {
                          if (friend_type === 'all_friend_match') {
                            if (target_data[param_ind]) {
                              this.$delete(target_data, param_ind);
                            }
                            return false;
                          }
                        } else {
                          ret = true;
                        }
                      } else {
                        let array_det = false;
                        for (let target_i in target_data[i]['val']) {
                          if (friend_data[i].operation[k].indexOf(target_data[i]['val'][target_i].value) !== -1) {
                            array_det = true;
                            break;
                          }
                        }
                        if (!array_det) {
                          if (friend_type === 'all_friend_match') {
                            this.$delete(target_data, param_ind);
                            return false;
                          }
                        } else {
                          ret = true;
                        }
                      }
                    }
                  }
                }
              } else if (friend_data[i].math_operator) {
                if (friend_data[i].math_operator.operator === '' || friend_data[i].math_operator.number_1 === '' || ((friend_data[i].math_operator.operator === 'between' || friend_data[i].math_operator.operator === 'not_between') && friend_data[i].math_operator.number_2 === '')) {
                  ret = true;
                } else {
                  let ret_math = false;
                  if (typeof target_data[i]['val'] === 'object') {
                    if (target_data[i]['val'].value) {
                      if (target_data[i]['val'].number_value) {
                        ret_math = this.f_checkFriendMath(target_data[i]['val'].number_value, friend_data[i].math_operator);
                      }
                      if (!ret_math) {
                        if (friend_type === 'all_friend_match') {
                          if (target_data[param_ind]) {
                            this.$delete(target_data, param_ind);
                          }
                          return false;
                        }
                      } else {
                        ret = true;
                      }
                    } else {
                      let array_count = 0.0;
                      for (let target_i in target_data[i]['val']) {
                        if (target_data[i]['val'][target_i].number_value) {
                          array_count += parseFloat(target_data[i]['val'][target_i].number_value);
                        }
                      }
                      ret_math = this.f_checkFriendMath(array_count, friend_data[i].math_operator);
                      if (!ret_math) {
                        if (friend_type === 'all_friend_match') {
                          this.$delete(target_data, param_ind);
                          return false;
                        }
                      } else {
                        ret = true;
                      }
                    }
                  } else {
                    if (target_data[i]['val'] !== '') {
                      ret = this.f_checkFriendMath(target_data[i]['val'], friend_data[i].math_operator);
                    } else {
                      ret = false;
                    }
                  }
                }
              } else {
                ret = true;
              }
            }
            if (ret && friend_type === 'any_one_of_friend_match') {
              break;
            }
          }
          if (friend_type === 'any_one_of_friend_match' && !ret) {
            return false;
          }
        } else {
          ret = true;
        }
        // mother control
        if (recording_param_data.if.mother && Object.keys(recording_param_data.if.mother).length > 0 && mother) {
          if (recording_param_data.if.mother[mother.name.value] === undefined) {
            if (recording_data_region[param_ind]) {
              this.$delete(recording_data_region, param_ind);
            }
            return false;
          } else {
            ret = true;
          }
        } else {
          ret = true;
        }
        // patient control
        if (recording_param_data.if.patient && recording_param_data.if.patient.length > 0) {
          let det = false;
          for (let i in recording_param_data.if.patient) {
            if (recording_param_data.if.patient[i].type === 'sex') {
              if (recording_param_data.if.patient[i].value === 'm' && this.patient.sex === '0') {
                det = true;
                break;
              } else if (recording_param_data.if.patient[i].value === 'f' && this.patient.sex !== '0') {
                det = true;
                break;
              }
            }
          }
          if (det) {
            ret = true;
          } else {
            if (recording_data_region[param_ind]) {
              this.$delete(recording_data_region, param_ind);
            }
            return false;
          }
        } else {
          ret = true;
        }
        // mother anatomy control
        if (recording_param_data.if.mother_anatomy && recording_param_data.if.mother_anatomy.length > 0) {
          let det = false;
          for (let i in recording_param_data.if.mother_anatomy) {
            if (recording_param_data.if.mother_anatomy[i].value === anatomy_region.value) {
              det = true;
              break;
            }
          }
          if (det) {
            ret = true;
          } else {
            if (recording_data_region[param_ind]) {
              this.$delete(recording_data_region, param_ind);
            }
            return false;
          }
        } else {
          ret = true;
        }
        // ana_parent control
        if (recording_param_data.if.ana_parent && recording_param_data.if.ana_parent.length > 0) {
          let det = false;
          for (let i in recording_param_data.if.ana_parent) {
            if (anatomy_region.parent.indexOf(recording_param_data.if.ana_parent[i].value) !== -1) {
              det = true;
              break;
            }
          }
          if (det) {
            ret = true;
          } else {
            if (recording_data_region[param_ind]) {
              this.$delete(recording_data_region, param_ind);
            }
            return false;
          }
        } else {
          ret = true;
        }
        //
      } else {
        ret = true;
      }
      if (!ret) {
        if (recording_data_region[param_ind]) {
          this.$delete(recording_data_region, param_ind);
        }
      }
      return ret;
    },
    add_anatomy: function () {
      if (this.selected_anatomy_region && this.selected_anatomy_region.value && !this.data['data'].anatomy[this.selected_anatomy_region.value]) {
        this.data['data'].anatomy[this.selected_anatomy_region.value] = {
          name: JSON.parse(JSON.stringify(this.selected_anatomy_region)),
          signs: {}
        };
      }
      this.$forceUpdate();
    },
    f_cleanAnatomyData: function () {
      this.data.data.anatomy = {};
    }
  },
  watch: {
    'p_selectedAnatomySchemaList': function () {
      this.$forceUpdate();
    },
    'mother_children.d_showAnatomyData': function () {
      if (this.mother_children.d_showAnatomyData) {
        this.$forceUpdate();
      }
    },
    'mother_children.d_showNonAnatomyData': function () {
      if (this.mother_children.d_showNonAnatomyData) {
        this.$forceUpdate();
      }
    },
    'p_wdmrSettings.group.show': function () {
      // console.log('this.d_expandAllClosedCollapse ', this.d_expandAllClosedCollapse);
      for (let i in this.d_groupShowList) {
        this.d_groupShowList[i].show = this.p_wdmrSettings.group.show;
      }
      this.$forceUpdate();
    },
    "param_change.id": function (id) {
      // console.log('param_change.id ', this.param_change.id);
      this.$forceUpdate();
      // this.build_form();
    }
  }
};

</script>

<style>
.popup_wisdom_param {
  visibility: visible;
  animation: fadeIn 0.3s;
  -webkit-animation: fadeIn 0.3s;
  background-color: #ffffff;
  /*border: solid 1px #89c1a3;*/
  /*color: #fff;*/
  width: 300px;
  /*text-align: center;*/
  border-radius: 3px;
  padding: 8px 0;
  position: fixed;
  z-index: 1000;
}

</style>

